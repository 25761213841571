import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from './auth.service';

@Component({
   templateUrl: './login.component.html',
})
export class LoginComponent {
   errorMessage: string;
   pageTitle = 'Log In';

   constructor(private authService: AuthService, private router: Router) {}
}
