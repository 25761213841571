import { Component, OnInit } from '@angular/core';

import { Format } from '../../refs/format';
import { FormatsService } from '../../services/formats.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { FormatDialogComponent } from '../format-dialog/format-dialog.component';
import { ValidationDialogComponent } from '../../validation-dialog/validation-dialog.component';

@Component({
   selector: 'pm-df-format-list',
   templateUrl: './format-list.component.html',
   styleUrls: ['./format-list.component.css', '../../shared/global.css'],
})
export class FormatListComponent implements OnInit {
   dataSource: MatTableDataSource<Format>;
   errorMessage: string;

   constructor(
      private formatsService: FormatsService,
      public dialog: MatDialog
   ) {}

   ngOnInit() {
      this.updateList();
   }

   updateList() {
      this.formatsService.getList().then(formats => {
         this.dataSource = new MatTableDataSource(formats);
      });
   }

   applyFilter(filterValue: string) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
   }

   addFormat() {
      const dialogRef = this.dialog.open(FormatDialogComponent, {
         width: '40vw',
         data: new Format(),
      });
      dialogRef.afterClosed().subscribe(result => {
         if (result && result.type !== null && result.title !== '') {
            this.formatsService.create(result).then(() => {
               this.updateList();
            });
         }
      });
   }

   edit(element) {
      const dialogRef = this.dialog.open(FormatDialogComponent, {
         width: '40vw',
         data: element,
      });
      dialogRef.afterClosed().subscribe(result => {
         if (
            result &&
            result.type !== null &&
            result.title !== '' &&
            result.id !== ''
         ) {
            this.formatsService.update(result).then(() => {
               this.updateList();
            });
         }
      });
   }

   delete(element) {
      const dialogRef = this.dialog.open(ValidationDialogComponent, {
         width: '40vw',
         data: {
           element : element,
           msg : 'ARE_YOU_SURE_FORMAT'
         },
      });
      dialogRef.afterClosed().subscribe(result => {
         if (result) {
            this.formatsService.delete(element.id).then(() => {
               this.updateList();
            });
         }
      });
   }
}
