import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Page } from '../refs/page';
import {PageService} from './cat/page.service';

// service in charge to keep track of common graphical objects used on selection
// exemple currentPage

@Injectable({
   providedIn: 'root',
})
export class DisplaySelectedObjectService {
   private selectedPageBehaviorSubject = new BehaviorSubject<Page | null>(null);
   public selectedPage$ = this.selectedPageBehaviorSubject.asObservable();

   constructor(
      private pageService: PageService
   ) {}

   changeSelectedPage(pageToSelect: Page | null): void {
         this.selectedPageBehaviorSubject.next(pageToSelect);
   }
}
