import { Campaign } from './campaign';
import { Page } from './page';

import { Catalog as LocalCatalog } from '../refs/catalog';

import { Ref } from './ref';
import {CatalogComment} from './catalog-comment';
import {CatalogFile} from '../refs/catalog-file';
import {CatalogAction} from './catalog-action';

export class Catalog {
   id: number;
   campaign: Campaign | Ref = null;
   pages: (Page | Ref)[] = [];
   catalogComments: (CatalogComment | Ref)[] = [];
   files: (CatalogFile | Ref)[] = [];
   catalogActions: (CatalogAction | Ref)[] = [];
   data: any = null;

   static toLocal(catalog: Catalog): LocalCatalog {
      let campagn = null;
      let pages = null;
      let catalogActions = null;
      // SOLUTION TEMPORAIRE, IL FAUT TROUVER POURQUOI ON ENREGISTRE DES selectedFormat COMME array
      if(catalog.data && typeof catalog.data.selectedFormat === 'object'){
       if(Array.isArray(catalog.data.selectedFormat)){
         console.log('selectedFormat corrigé ', catalog);
         catalog.data.selectedFormat = null;
       }
      }
      campagn = catalog.campaign
         ? Campaign.toLocal(catalog.campaign as Campaign)
         : null;
      pages = catalog.pages
         ? catalog.pages.map((page) => Page.toLocal(page as Page))
         : null;
      catalogActions = catalog.catalogActions
         ? catalog.catalogActions.map((action) => CatalogAction.toLocal(action as CatalogAction))
         : [];
      return {
         id: catalog.id,
         status:
            catalog.data && catalog.data.status ? catalog.data.status : null,
         company:
            catalog.data && catalog.data.company ? catalog.data.company : null,
         campagnyCouverture:
            catalog.data && catalog.data.campagnyCouverture
               ? catalog.data.campagnyCouverture.map((element) => element)
               : [],
         // catalog.data && catalog.data.campagnyCouverture ? catalog.data.campagnyCouverture : null,
         selectedWave:
            catalog.data && catalog.data.selectedWave
               ? catalog.data.selectedWave
               : null,
         selectedFormat:
            catalog.data && catalog.data.selectedFormat
               ? catalog.data.selectedFormat
               : null,
         copies:
            catalog.data && catalog.data.copies ? catalog.data.copies : null,
         routing:
            catalog.data && catalog.data.routing ? catalog.data.routing : false,
        dateValidite:
          catalog.data && catalog.data.dateValidite
            ? (catalog.data.dateValidite as string)
            : null,
        dateSouhaiteBat:
          catalog.data && catalog.data.dateSouhaiteBat
            ? (catalog.data.dateSouhaiteBat as Date)
            : null,
        routingDate:
          catalog.data && catalog.data.routingDate ? catalog.data.routingDate : null,
        routingQuantity:
          catalog.data && catalog.data.routingQuantity ? catalog.data.routingQuantity : null,
        routingComment:
          catalog.data && catalog.data.routingComment ? catalog.data.routingComment : null,
         routage:
            catalog.data && catalog.data.routage
               ? catalog.data.routage.map((element) => element)
               : [],
         encartage:
            catalog.data && catalog.data.encartage
               ? catalog.data.encartage
               : false,
        encarteurs:
          catalog.data && catalog.data.encarteurs
            ? catalog.data.encarteurs.map((element) => element)
            : [],
        encartageDate:
          catalog.data && catalog.data.encartageDate ? catalog.data.encartageDate : null,
        encartageQuantity:
          catalog.data && catalog.data.encartageQuantity ? catalog.data.encartageQuantity : null,
        encartageComment:
          catalog.data && catalog.data.encartageComment ? catalog.data.encartageComment : null,
         // encarteur: catalog.data ? catalog.data.encarteur : null,
         livraison:
            catalog.data && catalog.data.livraison
               ? catalog.data.livraison.map((element) => element)
               : [],
         livraisonCompany : catalog.data && catalog.data.livraisonCompany,
         livraisonQuantity : catalog.data && catalog.data.livraisonQuantity,
         campagn,
         pages,
         catalogActions,
         dateDiffusionDeb:
            catalog.data && catalog.data.dateDiffusionDeb
               ? catalog.data.dateDiffusionDeb
               : null,
         dateDiffusionFin:
            catalog.data && catalog.data.dateDiffusionFin
               ? catalog.data.dateDiffusionFin
               : null,
        catalogComments:
          catalog.data && catalog.data.catalogComments ? catalog.data.catalogComments : null,
        files: []
          // catalog.data && catalog.data.files ? catalog.data.files : null,
          // pour récupérer les fichiers, utiliser la fonction getList du CatalogFileService
      };
   }
   static fromLocal(catalog: LocalCatalog): Catalog {

      return {
         id: catalog.id,
         campaign: {
            '@id': catalog.campagn.id,
         },
         pages: (catalog.pages) ? catalog.pages.map((page) => ({
                  '@id': page.id,
                })) : [],
         catalogComments: (catalog.catalogComments) ? catalog.catalogComments.map((c) => ({
                  '@id': c.id,
                })) : [],
         files: (catalog.files) ? catalog.files.map((c) => ({
                  '@id': c.id,
                })) : [],
         catalogActions: (catalog.catalogActions) ? catalog.catalogActions.map((c) => ({
                  '@id': c.id,
                })) : [],
         data: {
            status: catalog.status,
            company: { '@id': catalog.company.id },
            selectedWave: catalog.selectedWave,
            selectedFormat: catalog.selectedFormat
               ? { '@id': catalog.selectedFormat.id }
               : null,
            copies: catalog.copies,
            routing: catalog.routing,
            routage: catalog.routage,
            dateValidite: catalog.dateValidite,
            dateSouhaiteBat: catalog.dateSouhaiteBat,
            routingDate: catalog.routingDate,
            routingQuantity: catalog.routingQuantity,
            encartage: catalog.encartage,
            encarteurs: catalog.encarteurs,
            // encarteur: catalog.encarteur,
            encartageDate: catalog.encartageDate,
            encartageQuantity: catalog.encartageQuantity,
            campagnyCouverture: catalog.campagnyCouverture,
            livraison: catalog.livraison,
            livraisonCompany : catalog.livraisonCompany,
            livraisonQuantity : catalog.livraisonQuantity,
            dateDiffusionDeb: catalog.dateDiffusionDeb,
            dateDiffusionFin: catalog.dateDiffusionFin,
            files: catalog.files ? catalog.files : []
         },
      };
   }
}
