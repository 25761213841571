// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
   production: false,
   environmentName: 'Recette',
   mails : {
     actifs: true,
     captureMails : [
       { label: 'Andrés Lartigue Binova', mail: 'alartigue@binova-it.fr'},
       { label: 'Céline', mail: 'celine.veillard@delta-force.com'},
       { label: 'Anne Cécile', mail: 'annececile.dubreuil@delta-force.com'},
     ]
   },
   urlBase : 'https://app.blgcloud.com/deltaforce-test/',
   blg_extranet_auth: {
      token:
         // token instance test
     'Ab833Bj4NJMWxjOh2U83Abc_hg1gqC9q4tBHvEwYwOPTA2EAAACYm-hkmCgQZUiMvts', // TEST admin anne cecile
     // 'Ac9ZriQ2vuLDb9z_6TJjZw0xwuVJr_AedLHBv3Y3j8AoAwcAAAB-ispjfhfyY0Gpn6U', // TEST animateur
     // 'Af5ibz4kaCAxC3uPQSt2k8-QB_259UhrYK2Mzy7UU_E-A1IAAACaJMJkmrHpZGD0TiA', // adhérent
     // 'AWE37AloxIRKYbyb8C6FsT8hRcmfGy02YJSKlSqxuTLCA-wCAABie4hkYgiwZF3K0NA', // TEST agence Sarah (MEDIAPILOTE RENNES)
   },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
