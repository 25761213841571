import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LabelComponent } from '../label/label.component';
import { Label } from '../refs/label';
import { LabelsService } from '../services/labels.service';
import { AuthService } from '../user/auth.service';
import {ValidationDialogComponent} from '../validation-dialog/validation-dialog.component';

@Component({
   selector: 'pm-df-labels',
   templateUrl: './labels.component.html',
   styleUrls: ['./labels.component.css', '../shared/global.css'],
})
export class LabelsComponent implements OnInit {
   get userRole() {
      if (this.authService.currentUser) {
         return this.authService.currentUser.role;
      }
      return '';
   }
   public dataSource: MatTableDataSource<Label>;
   @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
   @ViewChild(MatSort, { static: true }) sort: MatSort;

   label: string;

   constructor(
      private labelService: LabelsService,
      public dialog: MatDialog,
      private authService: AuthService
   ) {}

   ngOnInit() {
      this.updateTable();
   }

   updateTable() {
      this.labelService.getList().then(data => {
         this.dataSource = new MatTableDataSource<Label>(data);
      });
   }

   addLabels() {
      const dialogRef = this.dialog.open(LabelComponent, {
         width: '40vw',
         data: { label: '', picture: null, edit: true },
      });
      dialogRef.afterClosed().subscribe(result => {
         if (result && result.label !== '') {
            this.labelService
               .create({
                  title: result.label as string,
                  picture: result.picture as string,
               } as Label)
               .then(() => {
                  this.updateTable();
               });
         }
      });
   }

   deleteEvent($event, rowId) {
      const dialogRef = this.dialog.open(ValidationDialogComponent, {
         width: '40vw',
         data: {
           rowId : rowId,
           msg : 'ARE_YOU_SURE_LABEL'
         },
      });
      dialogRef.afterClosed().subscribe(result => {
         if (result) {
            this.labelService.delete(rowId).then(response => {
               this.updateTable();
            });
         }
      });

   }
   edit(element) {
      const mtype = element.title.split('.');
      const dialogRef = this.dialog.open(LabelComponent, {
         width: '40vw',
         data: { label: '', picture: element.picture, edit: false, element: element, mtype: mtype[mtype.length - 1]  as string},
      });
      dialogRef.afterClosed().subscribe(result => {
         if (result) {
            result.element.picture = result.picture;
            this.labelService.update(result.element).then(() => {
               this.updateTable();
            });
         }
      });
   }

   applyFilter(filterValue: string) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
         this.dataSource.paginator.firstPage();
      }
   }
}
