import { Injectable } from '@angular/core';
import { Label } from '../refs/label';

import { ConfigTemplate } from './cat/config-template';
import { ConfigService } from './cat/config.service';

@Injectable({
   providedIn: 'root',
})
export class LabelsService extends ConfigTemplate<Label> {
   constructor(protected configService: ConfigService) {
      super(configService);
   }

   getType(): string {
      return 'label';
   }
}
