import { Injectable } from '@angular/core';

import { DFApiService } from './dfapi.service';
import { DFApiReloaderService } from './dfapi-reloader.service';
import { LoadingService } from './loading.service';

import { Contact } from '../refs/contact';

@Injectable({
   providedIn: 'root',
})
export class ContactsService extends DFApiService {
   constructor(
      apiReloader: DFApiReloaderService,
      loadingService: LoadingService
   ) {
      super(apiReloader, loadingService);
      this.setLocation(['crm', 'contact']);
   }

   getList(): Promise<Contact[]> {
      const query = {
         filter: {
            tags: {
               tag: {
                  name: { $in: ['myCompany'] }, // , 'servicePoint'
               },
            },
         },
      };

      return new Promise((resolve, reject) => {
         this.list(query).then((res: Contact[]) => {
            resolve(res);
         });
      });
   }

  public async getListForCompany(id): Promise<any[]> {
      const query = {
         filter: {
           relationCompany : {
                  id : id, // , 'servicePoint'
            },
         },
         join :  ['tag'],
         field : ['id','tag','firstName','lastName', 'mail']
      };

      return new Promise((resolve, reject) => {
         this.list(query).then((res: Contact[]) => {
            resolve(res);
         });
      });
   }
}
