import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TitleCategory } from '../refs/title-category';
import {Title} from '../refs/title';


export interface DialogData {
   title: string;
   imposed: boolean;
   category: TitleCategory;
   edit: boolean;
   element: Title;
}

@Component({
   selector: 'pm-df-title',
   templateUrl: './title.component.html',
   styleUrls: ['./title.component.css'],
})
export class TitleComponent {
  // @ts-ignore
   categories = Object.values(TitleCategory);

   constructor(
      public dialogRef: MatDialogRef<TitleComponent>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData
   ) {}

   onNoClick(): void {
      this.dialogRef.close();
   }
}

