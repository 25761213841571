import { Injectable } from '@angular/core';

import { DFApiService } from '../dfapi.service';
import { DFApiReloaderService } from '../dfapi-reloader.service';
import { LoadingService } from '../loading.service';

import { Page as IntermediatePage } from '../../intermediate-refs/page';
import { Page } from '../../refs/page';
import { SlugifyService } from '../../shared/slugify.service';

import { CampaignService } from './campaign.service';
import { PageType } from '../../refs/page-type';
import { Campagn } from '../../refs/campagn';
import { Catalog } from '../../refs/catalog';

import { FieldResolverService } from '../field-resolver.service';
import { ProductsService } from '../products.service';
import {PageElementService} from './page-element.service';
import {Subject} from 'rxjs';
import {ArticlePictureService} from '../article-picture.service';

@Injectable({
   providedIn: 'root',
})
export class PageService extends DFApiService {
   constructor(
      private campaignService: CampaignService,
      private slugifyService: SlugifyService,
      private productsService: ProductsService,
      private pageElemenService: PageElementService,
      private articlePicturesService: ArticlePictureService,
      private fieldResolver: FieldResolverService,
      apiReloader: DFApiReloaderService,
      loadingService: LoadingService
   ) {
      super(apiReloader, loadingService);
      this.setLocation(['catalog', 'page']);
   }

   private pageDelete = new Subject<Page>();
   public pageDelete$ = this.pageDelete.asObservable();
   public page$ = this.getList;

   getJoinableElements(): string[] {
      return [
         'elements',
         'catalog',
         'campaign',
         // 'elements.article',
         'elements.article.pictures',
         'files.attachment'
      ];
   }

   public async resolve(val: IntermediatePage): Promise<Page> {
      const res = IntermediatePage.toLocal(val);
      if(!res.basket){
        res.basket = [];
      }
      const basket = await this.fieldResolver.resolveField(
         res.basket,
         article => article,
         (oldA, newA) => newA,
         this.productsService
      );
      res.basket = basket;
      const pageElement = await this.fieldResolver.resolveField(
          res.productsOnPage,
          article => article,
          (oldA, newA) => newA,
          this.pageElemenService
      );
      res.productsOnPage = pageElement;
      const featuredArticle = await this.fieldResolver.resolveOneField(
        res.featuredArticle,
        this.productsService
      );

      res.featuredArticle = featuredArticle;
      return res;
   }

   public async resolveFiles(val: IntermediatePage): Promise<Page> {
      const res = IntermediatePage.toLocalOnlyFiles(val);
      return res;
   }

  // TODO add order by order
   public async getList(): Promise<Page[]> {
      const query = {
         join: this.getJoinableElements(),
         order: ['id', 'asc']
      };
      const res = await this.list(query);
      const result = await (Promise.all(
         res.map(async page => await this.resolve(page))
      ) as Promise<Page[]>);
      return result;
   }

   // TODO add order by order
   public async getByCampagn(id: number): Promise<Page[]> {
    const query = {
      filter: {
        campaign: {
          id: {
            $in: [id],
          },
        }
      },
      join: this.getJoinableElements(),
      order: ['id', 'asc']
    };
    const res = await this.list(query);
    const result = await (Promise.all(
       res.map(async page => await this.resolve(page))
     ) as Promise<Page[]>);
    return result;
  }

   public async getById(id: number): Promise<Page> {
      const query = {
         filter: {
            id: {
               $in: [id],
            },
         },
         join: this.getJoinableElements(),
      };
      const res = await this.list(query);
      if (res.length === 0) {
         return null;
      }
      return await this.resolve(res[0]);
   }

  /**
   * TODO : à voir si on peut faire une fonction qui retourne un array compatible avec
   * l'array de l'attribut files
   */
   public async getFilesById(id: number): Promise<Page> {
      const query = {
        filter: {
          id: id,
        },
        join: ['files.attachment'],
        field: [
          'id',
          'files'
        ]
      };
      const res = await this.list(query);
      return await this.resolveFiles(res[0]);
/*      if (res.length === 0) {
        return null;
      }
 */
      // this.resolve(null);
      // return await this.resolveFiles(res[0]);
   }

  /**
   * TODO : à voir si on peut faire une fonction qui retourne un array compatible avec
   * l'array de l'attribut files
   */
  public async getAttachmentById(id: number): Promise<Page> {
    const query = {
      filter: {
        id: id,
      },
      join: ['files.attachment'],
      field: [
        'id',
        'files'
      ]
    };
    const res = await this.list(query);
    return await this.resolveFiles(res[0]);
    /*      if (res.length === 0) {
            return null;
          }
     */
    // this.resolve(null);
    // return await this.resolveFiles(res[0]);
  }


  public async create(obj: Page): Promise<Page> {
      const config = IntermediatePage.fromLocal(obj, this.slugifyService);
      delete config.id;
      // return new Promise((resolve, reject) => {
      //    this.put(config).then(res => {
      //       resolve(IntermediatePage.toLocal(res) as Page);
      //    });
      // });
      return IntermediatePage.toLocal(
       await this.put(config)
     ) as Page;
   }

   public async update(obj: Page): Promise<Page> {
      const config = IntermediatePage.fromLocal(obj, this.slugifyService);
      delete config.id;
      return new Promise((resolve, reject) => {
         this.patch((obj as any).id, config).then(res => {
            resolve(IntermediatePage.toLocal(res) as Page);
         });
      });
   }


  public async updateTitle(obj: Page): Promise<Page> {
    const config = IntermediatePage.fromLocal(obj, this.slugifyService);
    delete config.id;
    /*
    // TODO : POURQOUI J'ARRIVE PAS A METTRE À JOUR SEULEMENT LE TITRE ?
    console.log(config.data.title);
    const updateObject = {
      data : {
        title : config.data.title
      }
    };
    console.log('avant updateTitle');
    */

    return new Promise((resolve, reject) => {
      this.patch((obj as any).id, config).then(res => {
        resolve(IntermediatePage.toLocal(res) as Page);
      });
    });
  }


  public createPage(
      id: number,
      order,
      title,
      type: PageType,
      catalog: Catalog,
      campagn: Campagn,
      commentCover: string,
      files,
      // fileRight: Picture,
      // filesUpload: Picture[],

   ): Page {
      return {
         id,
         title,
         titleCustom: title,
         noTitle: false,
         type,
         section: null,
         order,
         tags: null,
         draft: true,
         version: 1,
         commentCover: commentCover,
         commentInfoConcession: null,
         preview: null,
         titles: [],
         productsOnPage: [],
         basket: [],
         logo: null,
         files: files,
         // fileRight: fileRight,
         // filesUpload: filesUpload,
         catalog: catalog,
         campagn: campagn,
         // fileRightSelected : false,
         fileSelectedAdh: null,
         filesSelectedCover: [],
         // fileConcession: null,
         featuredArticle: null,
         customPage: false,
         photo: null,
         visuelDelta: null,
         pageTypeConcession: null,
         gabaritConcession: null,
         visuelPageConcession: null,
         offreCouverture: 1,
         pageComments: [],
         sousTitresVignetes: []
      };
   }

   setPagedelete(page: Page) {
      this.pageDelete.next(page);
   }

}
