<div class="button-zone">
  <button (click)="updateTable()" mat-button>
    <mat-icon>cached</mat-icon>
    {{ 'RELOAD' | translate }}
    <span class="fa fa-spinner spinner download-spinner" *ngIf="isLoading"></span>
  </button>
</div>
<mat-table [dataSource]="dataSource" matSort>

  <ng-container cdkColumnDef="companie">
    <mat-header-cell *cdkHeaderCellDef> Companie </mat-header-cell>
    <mat-cell *cdkCellDef="let row">
      {{ row.company?.companyName }}
    </mat-cell>
  </ng-container>
  <ng-container cdkColumnDef="nom">
    <mat-header-cell *cdkHeaderCellDef> Animateur </mat-header-cell>
    <mat-cell *cdkCellDef="let row"> {{ row.nom }} </mat-cell>
  </ng-container>

  <!-- Header and Row Declarations -->
  <div class="containercard">
    <mat-header-row
      *cdkHeaderRowDef="['companie', 'nom']"
    ></mat-header-row>
    <mat-row
      *cdkRowDef="let row; columns: ['companie', 'nom']"
    ></mat-row>
  </div>
</mat-table>
