import { Injectable } from '@angular/core';

import { User } from '../refs/user';
import {Observable, Subject} from 'rxjs';

@Injectable({
   providedIn: 'root',
})
export class AuthService {
   // constructor() {
   //     super();
   //     this.setLocation(['user', 'user']);
   // }
   currentUser: User;
   redirectUrl: string;
   token: any = null;
   afterLogin = new Subject<User>() ;

   get afterLoginObservable(): Observable<User> {
     return this.afterLogin.asObservable();
   }
   get isLoggedIn(): boolean {
      return !!this.currentUser;
   }

   public setToken(token: any) {
      this.token = token;
   }

   public getToken() {
      if (this.token) {
         return this.token;
      }
      return '';
   }

   get user(): User {
      return this.currentUser;
   }

   login(user: User): void {
      const realUser = new User();
      for(const att of Object.keys(user)){
          realUser[att] = user[att];
      }
      this.currentUser = realUser;
   }

   logout(): void {
      this.currentUser = null;
   }
}
