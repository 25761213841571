import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
   providedIn: 'root',
})
/**
 * Ce service permet de notifier la liste des produits visible dans
 * la page de construction des pages après d'avoir fait un le drop d'un article
 * sur la section de droite.
 */
export class ArticleDroppedService {

  articleDroppedSubject = new Subject();

  get articleDropped(){
    return this.articleDroppedSubject.asObservable();
  }

}
