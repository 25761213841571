import { Injectable } from '@angular/core';
import {DFApiReloaderService} from './dfapi-reloader.service';
import {LoadingService} from './loading.service';
import {DFApiService} from './dfapi.service';


@Injectable({
  providedIn: 'root'
})
export class ArticlePurchasePriceService extends DFApiService {

  constructor(
      apiReloader: DFApiReloaderService,
      loadingService: LoadingService
  ) {
    super(apiReloader, loadingService);
    this.setLocation(['article', 'supplierPrice']);
  }

  public async getPrice(articleId : number): Promise<any> {
    const query = {
            filter : {
              article : {
                id : articleId
              }
            }
    } as any;
    return await this.list(query);
  }


}
