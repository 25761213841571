<!--<div cdkTrapFocus [cdkTrapFocusAutoCapture]="true" mat-dialog-content>-->
<!--   <mat-form-field class="full-width-field">-->
<!--      <input matInput cdkFocusInitial [(ngModel)]="data.title" placeholder="Titre" />-->
<!--   </mat-form-field>-->
<!--   <mat-form-field class="full-width-field">-->
<!--      <mat-label>{{ 'category' | translate }}</mat-label>-->
<!--      <mat-select [(ngModel)]="data.category">-->
<!--         <mat-option *ngFor="let category of categories" [value]="category">-->
<!--            {{ category | translate }}-->
<!--         </mat-option>-->
<!--      </mat-select>-->
<!--   </mat-form-field>-->
<!--</div>-->
<!--<div mat-dialog-actions>-->
<!--   <button mat-button (click)="onNoClick()">{{ 'CLOSE' | translate }}</button>-->
<!--   <button mat-button [mat-dialog-close]="data" >{{ 'VALIDATE' | translate }}</button>-->
<!--</div>-->
<button mat-icon-button class="close-button" [mat-dialog-close]="true" style="float: right">
  <mat-icon class="close-icon" color="default">close</mat-icon>
</button>
<div mat-dialog-content class="dialog">
  <h3 *ngIf="!data.edit">Edition du Picto</h3>
  <mat-form-field class="full-width-field">
    <input *ngIf="!data.edit" matInput [(ngModel)]="data.element.label" placeholder="Titre" />
    <input *ngIf="data.edit" matInput [(ngModel)]="data.title" placeholder="Titre" />
  </mat-form-field>
     <mat-form-field *ngIf="data.edit" class="full-width-field">
        <mat-label>{{ 'category' | translate }}</mat-label>
        <mat-select [(ngModel)]="data.category">
          <mat-option  [value]="">
            Veuillez sélectionner une catégorie
          </mat-option>
           <mat-option *ngFor="let category of categories" [value]="category">
              {{ category | translate }}
           </mat-option>
        </mat-select>
     </mat-form-field>
     <mat-form-field *ngIf="!data.edit" class="full-width-field">
        <mat-label>{{ 'category' | translate }}</mat-label>
        <mat-select [(ngModel)]="data.element.category">
           <mat-option  [value]="">
             Veuillez sélectionner une catégorie
           </mat-option>
           <mat-option *ngFor="let category of categories" [value]="category">
              {{ category | translate }}
           </mat-option>
        </mat-select>
     </mat-form-field>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">{{ 'CLOSE' | translate }}</button>
    <button mat-button [mat-dialog-close]="data" cdkFocusInitial>{{ 'VALIDATE' | translate }}</button>
  </div>
</div>
