import { Component, OnInit, Inject } from '@angular/core';
import {
   MatDialogRef,
   MAT_DIALOG_DATA,
} from '@angular/material/dialog';

@Component({
   selector: 'pm-df-validation-dialog',
   templateUrl: './validation-dialog.component.html',
   styleUrls: ['./validation-dialog.component.css'],
})

export class ValidationDialogComponent implements OnInit {
   public msg: string;

   constructor(
      public dialogRef: MatDialogRef<ValidationDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any
   ) {}

   ngOnInit() {
     this.msg = '';
     if ( this.data.hasOwnProperty('msg') ) {
        this.msg = this.data.msg;
     }
   }

   onNoClick(): void {
      this.dialogRef.close();
   }
}
