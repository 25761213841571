import { TypeFormat as LocalTypeFormat } from '../refs/type-format';
import {SlugifyService} from '../shared/slugify.service';

export class TypeFormat {
   id: number;
   label: string;
   data: any = null;

   static toLocal(typeFormat: TypeFormat): LocalTypeFormat {
      return {
         id: typeFormat.id,
         label: typeFormat.label,
         typeFormat: typeFormat.data && typeFormat.data.typeFormat ? typeFormat.data.typeFormat : null
      };
   }
   static fromLocal(typeFormat: LocalTypeFormat,
                    slugifyService: SlugifyService): TypeFormat {
      return {
         id: typeFormat.id,
         label : typeFormat.label,
         data: {
            typeFormat: typeFormat.typeFormat
         },
      };
   }
}
