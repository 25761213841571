import { Company } from './company';
import { Role } from './role';

export class User {
   id: number;
   username: string;
   mail: string;
   company: Company;
   role: Role;
   fullname: string;
   crmId: string;
   relatedCompanies: Company[];

  /**
   * Get all user companys.
   * J'ai fait cette fonction, car je ne suis pas sûr que le champ relatedCompanies
   * inclue la company de l'utilisateur
   */
  public getUserCompanysArray = function() {
      const companys = [this.company.id];
      for (const a of this.relatedCompanies) {
        if (!companys.includes(a.id)) {
          companys.push(a.id);
        }
      }
      return companys;
    }
}
