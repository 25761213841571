import { Config } from '../../intermediate-refs/config';

import { ConfigService } from './config.service';


export abstract class ConfigTemplate<TypeConfig> {
   constructor(protected configService: ConfigService) {
   }

   abstract getType(): string;

   public getList(additionalQuery = null): Promise<TypeConfig[]> {
      const query = {
         filter: {
            type: {
               $in: [this.getType()],
            },
         },
        order: ['data.label', 'asc']
      } as any;
      if (additionalQuery && additionalQuery.filter) {
         query.filter.id = additionalQuery.filter.id;
      }
      return new Promise((resolve, reject) => {
         this.configService
             .list(query)
             .then(res => resolve(res.map(val => Config.toLocal(val) as any)));
      });
   }

   public getById(id: number): Promise<TypeConfig> {
      const query = {
         filter: {
            type: {
               $in: [this.getType()],
            },
            id: {
               $in: [id],
            },
         },
      };
      return new Promise((resolve, reject) => {
         this.configService
             .list(query)
             .then(res =>
                 resolve(
                     res.length === 0 ? null : (Config.toLocal(res[0]) as any)
                 )
             );
      });
   }
   public getByIds(ids: number[]): Promise<TypeConfig[]> {
      const query = {
         filter: {
            type: {
               $in: [this.getType()],
            },
            id: {
               $in: ids,
            },
         },
      };
      return new Promise((resolve, reject) => {
         this.configService
             .list(query)
             .then((res => resolve(res.map(val => Config.toLocal(val) as any)))
             );
      });
   }

   public create(obj: TypeConfig): Promise<TypeConfig> {
       const config = Config.fromLocal(
          obj,
          this.getType(),
          this.configService.getSlugifyService()
      );
       delete config.id;
       return new Promise((resolve, reject) => {
         this.configService
             .put(config)
             .then(res => {
               res.type = this.getType();
               resolve(Config.toLocal(res) as any);
             });
      });
   }

   public update(obj: TypeConfig): Promise<TypeConfig> {
      const config = Config.fromLocal(
          obj,
          this.getType(),
          this.configService.getSlugifyService()
      );
      delete config.id;
      return new Promise((resolve, reject) => {
         this.configService
             .patch((obj as any).id, config)
             .then(res => resolve(Config.toLocal(res) as any));
      });
   }


  public createOrUpdate(obj: TypeConfig): Promise<TypeConfig> {
    const config = Config.fromLocal(
      obj,
      this.getType(),
      this.configService.getSlugifyService()
    );
    delete config.id;
    if ((obj as any).id === undefined) {
       // Create
      return new Promise((resolve, reject) => {
        this.configService
          .put(config)
          .then(res => {
            res.type = this.getType();
            resolve(Config.toLocal(res) as any);
          });
      });
    } else {
      // Update
      return new Promise((resolve, reject) => {
        this.configService
          .patch((obj as any).id, config)
          .then(res => resolve(Config.toLocal(res) as any));
      });
    }

  }


  public delete(id: number): Promise<number> {
      return new Promise((resolve, reject) => {
         this.configService.delete(id).then(res => resolve(id));
      });
   }


}
