<div>
   <div class="example-container">
      <div class="article-list-header-container">
        <h6>Liste complète des produits</h6>
        <div class="filters">
           <mat-form-field class="filter">
              <mat-label>{{ 'FILTER' | translate }}</mat-label>
              <input
                 autocomplete="off"
                 [(ngModel)]="searchText"
                 (ngModelChange)="onSearchTextChange()"
                 matInput
              />
           </mat-form-field>
           <mat-form-field class="filter">
              <mat-select
                 name="supplier-filter"
                 [(ngModel)]="supplier"
                 (ngModelChange)="onSearchTextChange()"
                 placeholder="Filtrer par Fournisseur"
              >
                 <input class="search-supplier" placeholder="Tapez le nom d'un fournisseur" [(ngModel)]="searchPresta" (ngModelChange)="filterPrestatairesList()">
                 <mat-option value="0">Tous</mat-option>
                 <mat-option *ngFor="let supplier of selectedSuppliers" [value]="supplier.id">
                    {{ supplier.title }}
                 </mat-option>
              </mat-select>
           </mat-form-field>
        </div>
  <!--
        <mat-form-field class="filter-2">
           <mat-chip-list #chipList aria-label="Tag selection">
              <mat-chip
                 *ngFor="let tag of selectedTags"
                 [removable]="removable"
                 (removed)="removeTag(tag.name)"
              >
                 {{ tag.title }}
                 <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input
                 placeholder="Tags supplémentaires"
                 #tagInput
                 [formControl]="tagCtrl"
                 [matAutocomplete]="auto"
                 [matChipInputFor]="chipList"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 (ngModelChange)="autocompleteChange($event)"
              />
           </mat-chip-list>
           <mat-autocomplete
              #auto="matAutocomplete"
              (optionSelected)="selectedTag($event)"
           >
              <mat-option *ngFor="let tag of filteredTags" [value]="tag.name">
                 {{ tag.title }}
              </mat-option>
           </mat-autocomplete>
        </mat-form-field>
        -->
      </div>
      <div
         class="example-list"
         id="articlesList"
         cdkDropList
         [cdkDropListData]="articles"
         [cdkDropListConnectedTo]="['selectedArticlesList']"
         (cdkDropListDropped)="drop($event)"
      >
         <h4 *ngIf="articlesSource.unfiltered">Veuillez choisir un fournisseur ou saisir une désignation ou une référence pour faire une recherche de produit</h4>
         <p *ngIf="articles.length == 200">Seulement {{articles.length}} articles sont affichés, veuillez affiner votre recherche</p>
         <pm-df-article-details
            class="example-box flex-item"
            *ngFor="let article of articleViews"
            [article]="article"
            (favoriteStateChange)="onFavoriteStateChange(article)"
            cdkDrag
            [cdkDragData]="article"
         >
         </pm-df-article-details>
      </div>
   </div>
</div>
