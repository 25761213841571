import { Injectable } from '@angular/core';
import {DFApiService} from './dfapi.service';
import {DFApiReloaderService} from './dfapi-reloader.service';
import {LoadingService} from './loading.service';

import {TypeFormat} from '../refs/type-format';
import {TypeFormat as IntermediateTypeFormat} from '../intermediate-refs/type-format';
import {SlugifyService} from '../shared/slugify.service';

@Injectable({
  providedIn: 'root'
})
export class TypeFormatService extends DFApiService {

  constructor(
      apiReloader: DFApiReloaderService,
      loadingService: LoadingService,
      private slugifyService: SlugifyService,
  ) {
    super(apiReloader, loadingService);
    this.setLocation(['catalog', 'typeFormat']);
  }

  public typeFormat$ = this.getList;

  getJoinableElements(): string[] {
    return [
    ];
  }

  public getList(): Promise<TypeFormat[]> {
    const query = {
      // join: this.getJoinableElements(),
    };
    return new Promise((resolve, reject) => {
      this.list(query).then(res => {
        resolve(res.map(val => IntermediateTypeFormat.toLocal(val)));
      });
    });
  }

  public getById(id: number): Promise<TypeFormat> {
    const query = {
      filter: {
        id: {
          $in: [id],
        },
      },
      // join: this.getJoinableElements(),
    };
    return new Promise((resolve, reject) => {
      this.list(query).then(res => {
        resolve(
            res.length === 0
                ? null
                : (IntermediateTypeFormat.toLocal(res[0]) as TypeFormat)
        );
      });
    });
  }
  public getByLabel(label: string): Promise<TypeFormat> {
    const query = {
      filter: {
        label: {
          $in: [label],
        },
      },
    };
    return new Promise((resolve, reject) => {
      this.list(query).then(res => {
        resolve(
            res.length === 0
                ? null
                : (IntermediateTypeFormat.toLocal(res[0]) as TypeFormat)
        );
      });
    });
  }

  public create(obj: TypeFormat): Promise<TypeFormat> {
    const config = IntermediateTypeFormat.fromLocal(obj, this.slugifyService);
    delete config.id;
    return new Promise((resolve, reject) => {
      this.put(config).then(res => {
        resolve(IntermediateTypeFormat.toLocal(res) as TypeFormat);
      });
    });
  }

  public update(obj: TypeFormat): Promise<TypeFormat> {
    const config = IntermediateTypeFormat.fromLocal(obj, this.slugifyService);
    delete config.id;
    return new Promise((resolve, reject) => {
      this.patch((obj as any).id, config).then(res => {
        resolve(IntermediateTypeFormat.toLocal(res) as TypeFormat);
      });
    });
  }
}
