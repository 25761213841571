import { Injectable } from '@angular/core';

import { DFApiService } from './dfapi.service';
import { Article } from '../refs/article';
import { DFApiReloaderService } from './dfapi-reloader.service';
import { LoadingService } from './loading.service';

@Injectable({
   providedIn: 'root',
})
export class ArticlePictureService extends DFApiService {
   constructor(
      apiReloader: DFApiReloaderService,
      loadingService: LoadingService
   ) {
      super(apiReloader, loadingService);
      this.setLocation(['article', 'articlePicture']);
   }

   getPictures(articles: Article[]): Promise<any> {
      const query = {
         filter: {
            article: {
               id: {
                  $in: articles.map(article => article.id),
               },
            },
         },
      };
      return new Promise((resolve, reject) => {
         this.list(query).then(res => {
            resolve(
               res.map(articlePicture => {
                  return {
                     id: articlePicture.id,
                     articleId: articlePicture.article
                        ? articlePicture.article['@id']
                        : 0,
                     picture: articlePicture.picture,
                  };
               })
            );
         });
      });
   }
}
