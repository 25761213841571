import { Injectable } from '@angular/core';
import { AnimateurCompanie } from '../refs/animateur-companie';
import { ConfigTemplate } from './cat/config-template';
import { ConfigService } from './cat/config.service';

@Injectable({
   providedIn: 'root',
})
export class AnimateurCompanieService extends ConfigTemplate<AnimateurCompanie> {
   constructor(protected configService: ConfigService) {
      super(configService);
   }

   getType(): string {
      return 'animateurcompanie';
   }
}
