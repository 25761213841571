import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {TypeFormatService} from '../services/type-format.service';
import {TypeFormat} from '../refs/type-format';
import {ValidationDialogComponent} from '../validation-dialog/validation-dialog.component';
import {TypeFormatDialogComponent} from './type-format-dialog/type-format-dialog.component';

@Component({
  selector: 'pm-df-type-format',
  templateUrl: './type-format.component.html',
  styleUrls: ['./type-format.component.css', '../shared/global.css']
})
export class TypeFormatComponent implements OnInit {
  dataSource: MatTableDataSource<TypeFormat>;
  errorMessage: string;
  constructor(
      private typeFormatService: TypeFormatService,
      public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.updateList();
  }

  updateList() {
    this.typeFormatService.getList().then(formats => {
      this.dataSource = new MatTableDataSource(formats);
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  addFormat() {
    const dialogRef = this.dialog.open(TypeFormatDialogComponent, {
      width: '40vw',
      data: new TypeFormat(),
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result) {
        this.typeFormatService.create(result).then(() => {
          this.updateList();
          this.typeFormatService.getList().then( type => {
                this.dataSource =  new MatTableDataSource(type);
              }
          );
        });
      }
    });
  }

  edit(element) {
    const dialogRef = this.dialog.open(TypeFormatDialogComponent, {
      width: '40vw',
      data: element,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.typeFormatService.update(result).then(() => {
          this.updateList();
        });
      }
    });
  }

  delete(element) {
    const dialogRef = this.dialog.open(ValidationDialogComponent, {
      width: '40vw',
      data: {
        element : element,
        msg : 'ARE_YOU_SURE_FORMAT_TYPE'
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.typeFormatService.delete(element.id).then(() => {
          this.updateList();
        });
      }
    });
  }

}
