import { Injectable } from '@angular/core';
import {ContactsService} from './contacts.service';
import {CompaniesService} from './companies.service';
import {TagsService} from './tags.service';

@Injectable({
  providedIn: 'root'
})
export class AnimateurService {


  public zones: any;
  public animateurs: any = null;

  constructor(
    private contactsService: ContactsService,
    private companiesService: CompaniesService,
    private tagsService: TagsService,
  ) {

  }

  public async getAnimateursParZone(){
    this.animateurs = {};
    this.zones = this.getZonesConfig();
    const contacts = await this.contactsService.getListForCompany('5bae3885dcb51500696c7592');
    for( const contact of contacts){
      for (const t of contact.tag){
        if (this.zones.hasOwnProperty(t)){
          this.zones[t].animateurs.push(contact);
        }
      }
    }
    this.companiesService.setTagsService(this.tagsService);
    const adherents = await this.companiesService.getList();
    for (const company of adherents) {
      const companyTags = await this.companiesService.getCompanyTags(company.id);
      let animateurs = null;
      for (const t of companyTags) {
        for (const key of Object.keys(this.zones)) {
          const z = this.zones[key];
          if (z.title === t.title) {
            animateurs = z.animateurs;
            break;
          }
        }
      }
      this.animateurs[company.id] = animateurs;
    }
    return this.animateurs;
  }

  public getZonesConfig(){
    return {
      animSE : { title : 'Animateur SUD-EST', animateurs : []},
      animateurSO : { title : 'Animateur SUD-OUEST', animateurs : []},
      animateurO : { title : 'Animateur OUEST', animateurs : []},
      animateurNO : { title : 'Animateur NORD-EST', animateurs : []},
    }
  }

}
