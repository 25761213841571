export enum PageType {
   MANDATORY = 'Mandatory',
   THEMATIC = 'Thematic',
   FREE = 'Free',
   COVER = 'Cover',
   ALL = 'All',
   CONCESSION = 'Concession',
   ADDRESSES = 'Addresses',
   MOCK = 'TODO'
}
