import { Injectable } from '@angular/core';

import { DFApiService } from '../dfapi.service';
import { DFApiReloaderService } from '../dfapi-reloader.service';
import { LoadingService } from '../loading.service';

@Injectable({
   providedIn: 'root',
})
export class CampaignService extends DFApiService {
   constructor(
      apiReloader: DFApiReloaderService,
      loadingService: LoadingService
   ) {
      super(apiReloader, loadingService);
      this.setLocation(['catalog', 'campaign']);
   }
}
