import { Injectable } from '@angular/core';

import { DFApiService } from '../dfapi.service';
import { DFApiReloaderService } from '../dfapi-reloader.service';
import { LoadingService } from '../loading.service';

import { SlugifyService } from '../../shared/slugify.service';

@Injectable({
   providedIn: 'root',
})
export class ConfigService extends DFApiService {
   constructor(
      apiReloader: DFApiReloaderService,
      loadingService: LoadingService,
      private slugifyService: SlugifyService
   ) {
      super(apiReloader, loadingService);
      this.setLocation(['catalog', 'config']);
   }

   public getSlugifyService(): SlugifyService {
      return this.slugifyService;
   }
}
