import { Contact } from '../refs/contact';
import { CampaignCompany } from './campaign-company';
import { Catalog } from './catalog';
import { Page } from './page';

import { Campagn } from '../refs/campagn';
import { Company } from '../refs/company';
import { CampagnTitle } from '../refs/campagn-title';
import { Format } from '../refs/format';
import { Wave } from '../refs/wave';

import { Ref } from './ref';
import {CampagneType} from '../refs/campagne-type';

export class Campaign {
   id: number;
   contact: Contact | Ref = null; // partner/contact
   companies?: (CampaignCompany | Ref)[] = [];
   catalogs: (Catalog | Ref)[] = []; // catalog/catalog
   templatePages: (Page | Ref)[] = []; // catalog/page
   data: any; // Other objects

   static toLocal(campaign: Campaign): Campagn {
      return {
         id: campaign.id,
         title:
            campaign.data && campaign.data.title ? campaign.data.title : null,
         tag: campaign.data && campaign.data.tag ? campaign.data.tag : null,
         waves:
            campaign.data && campaign.data.waves
               ?  (campaign.data.waves as Wave[])
               : [],
         contact: campaign.contact as Contact,
         customers: campaign.companies
            ? campaign.companies.map(
                 obj => (obj as CampaignCompany).company as Company
              )
            : [],
         openingDate:
            campaign.data && campaign.data.openingDate
               ? (campaign.data.openingDate as Date)
               : null,
         titleListSelected:
            campaign.data && campaign.data.titleListSelected
               ? (campaign.data.titleListSelected as CampagnTitle[])
               : [],
         formats:
            campaign.data && campaign.data.formats
               ? (campaign.data.formats as Format[])
               : [],
         typeFormats:
            campaign.data && campaign.data.typeFormats
            ? campaign.data.typeFormats
            : null,
         catalogs: campaign.catalogs
            ? campaign.catalogs.map(catalog =>
                 Catalog.toLocal(catalog as Catalog)
              )
            : [],
         pages: campaign.templatePages
            ? campaign.templatePages.map(page => Page.toLocal(page as Page))
            : [], // Use Page.toCatalogue
         commercialConditions:
            campaign.data && campaign.data.commercialConditions
               ? campaign.data.commercialConditions
               : null,
         validate:
            campaign.data && campaign.data.validate ? campaign.data.validate
                : null,
        lienCondCom:
            campaign.data && campaign.data.lienCondCom ? campaign.data.lienCondCom
                : '',
        archive:
            campaign.data && campaign.data.archive ? campaign.data.archive
                : false,
        campagneType:
            campaign.data && campaign.data.campagneType ? campaign.data.campagneType
                : null as CampagneType,
        noWawes:
          campaign.data && campaign.data.noWawes
            ? campaign.data.noWawes
            : false,
        noCommercialConditions:
          campaign.data && campaign.data.noCommercialConditions
            ? campaign.data.noCommercialConditions
            : false,
         dateBAT:
          campaign.data && campaign.data.dateBAT
            ? campaign.data.dateBAT
            : null,
      };
   }

   static fromLocal(campagn: Campagn): Campaign {
      return {
         id: campagn.id,
         contact:
            campagn.contact && campagn.contact.id
               ? {
                    '@id': campagn.contact.id,
                 }
               : null,
         catalogs: campagn.catalogs.map(catalog => ({ '@id': catalog.id })), // Use Catalog.fromCatalogue
         templatePages: campagn.pages.map(page => ({ '@id': page.id })), // Use Page.fromCatalogue
         data: {
            title: campagn.title,
            tag: campagn.tag,
            waves: campagn.waves,
            openingDate: campagn.openingDate,
            titleListSelected: campagn.titleListSelected.map(campagnTitle => ({
               id: campagnTitle.id,
               imposed: campagnTitle.imposed,
               title: {
                  '@id': campagnTitle.title.id,
               },
            })),
            formats: campagn.formats.map(format => ({
               '@id': format.id,
            })),
            typeFormats: campagn.typeFormats
                ? { '@id': campagn.typeFormats.id }
                : null,
            commercialConditions: campagn.commercialConditions,
            validate: campagn.validate,
            lienCondCom: campagn.lienCondCom,
            archive: campagn.archive,
            campagneType: campagn.campagneType,
            noWawes: campagn.noWawes,
            noCommercialConditions: campagn.noCommercialConditions,
            dateBAT: campagn.dateBAT,
         },
      };
   }
}
