import { Page } from './page';

import { Article } from '../refs/article';
import { ArticleOnPage } from '../refs/article-on-page';

import { Ref } from './ref';

import { SlugifyService } from '../shared/slugify.service';

export class PageElement {
   id: number;
   page: Page | Ref = null;
   article: Article | Ref = null;
   data: any = null;

   static toLocal(pageElement: PageElement): ArticleOnPage {
      return {
         id: pageElement.id,
         page: pageElement.page ? Page.toLocal(pageElement.page as Page) : null,
         article: pageElement.article as Article,
         primary:
            pageElement.data && pageElement.data.primary
               ? pageElement.data.primary
               : false,
         price:
            pageElement.data && pageElement.data.price
               ? pageElement.data.price
               : 0,
         internalRef:
            pageElement.data && pageElement.data.internalRef
               ? pageElement.data.internalRef
               : 0,
         priceSuggest:
             pageElement.data && pageElement.article
                 ? pageElement.data.priceSuggest
                 : 0,
         description:
             pageElement.data && pageElement.article
                 ? pageElement.data.description
                 : 0,
         picture:
             pageElement.data && pageElement.article
                 ? pageElement.data.picture
                 : 0,
         labels:
            pageElement.data && pageElement.data.labels
               ? pageElement.data.labels
               : null,
      };
   }

   static fromLocal(
      articleOnPage: ArticleOnPage,
      slugifyService: SlugifyService
   ): PageElement {
      return {
         id: articleOnPage.article.id,
         page: { '@id': articleOnPage.page.id },
         article: { '@id': articleOnPage.article.id },
         data: {
            primary: articleOnPage.primary,
            price: articleOnPage.price,
            internalRef: articleOnPage.internalRef,
            priceSuggest: articleOnPage.priceSuggest,
            description: articleOnPage.description,
            picture: articleOnPage.picture,
            labels: articleOnPage.labels
               ? articleOnPage.labels
               : null,
         },
      };
   }
}
