import { Injectable } from '@angular/core';

import { DFApiService } from './dfapi.service';
import { Company } from '../refs/company';
import { DFApiReloaderService } from './dfapi-reloader.service';
import { LoadingService } from './loading.service';
import {TagsService} from './tags.service';

@Injectable({
   providedIn: 'root',
})
export class CompaniesService extends DFApiService {
   private tagsService: TagsService;

  constructor(
      apiReloader: DFApiReloaderService,
      tagsService: TagsService,
      loadingService: LoadingService
   ) {
      super(apiReloader, loadingService);
      this.setLocation(['partner', 'company']);
   }

   getList(): Promise<Company[]> {
      const query = {
         filter: {
            tags: {
               tag: {
                  name: { $in: ['retailer'] },
               },
            },
         },
         order: ['companyName', 'asc']
      };

      return new Promise((resolve, reject) => {
         this.list(query).then((res: Company[]) => {
            resolve(res);
         });
      });
   }
   getListEncarteur(): Promise<any[]> {
      const query = {
        filter: {
          tags: {
            tag: {
              name: { $in: ['usertag_ro5uw'] },
            },
          },
        }
      };
      return new Promise((resolve, reject) => {
         this.list(query).then((res: any[]) => {
            resolve(res);
         });
      });
   }
   getListRouteur(): Promise<Company[]> {
      const query = {
         filter: {
            tags: {
               tag: {
                  name: { $in: ['usertag_evway'] },
               },
            },
         }
      };

      return new Promise((resolve, reject) => {
         this.list(query).then((res: Company[]) => {
            resolve(res);
         });
      });
   }
  public async getEncarteur(companyName): Promise<any> {
    const query = {
      filter: {
        tags: {
          tag: {
            name: { $in: ['usertag_ro5uw'] },
          },
        },
        companyName: {
          $eq: companyName,
        }
      }
    }as any;
    return await this.list(query);
  }
  public async getRouteur(companyName): Promise<any> {
    const query = {
      filter: {
        tags: {
          tag: {
            name: { $in: ['usertag_evway'] },
          },
        },
        companyName: {
          $eq: companyName,
        }
      }
    }as any;
    return await this.list(query);
  }

  public setTagsService(tagsService: TagsService) {
    this.tagsService = tagsService;
    this.init();
  }

  public async getCompanyTags(id): Promise<any[]> {
    const query = {
      filter: {
        id : id
      },
      field : ['id', 'tags.tag.name'],
      join : ['tags']
    } as any;
    const list = await this.list(query);
    const tagsArray = [];
    for (const co of list){
      for(const t of co.tags){
        tagsArray.push(t.tag['@id']);
      }
    }
    return await this.tagsService.getTags(tagsArray);
  }
}
