import { Injectable } from '@angular/core';

import { DFApiService } from './dfapi.service';
import { Article } from '../refs/article';
import { DFApiReloaderService } from './dfapi-reloader.service';
import { LoadingService } from './loading.service';
import {Page} from '../refs/page';
import {ArticlePurchasePriceService} from './article-purchase-price.service';

@Injectable({
   providedIn: 'root',
})
export class ProductsService extends DFApiService {
   constructor(
      apiReloader: DFApiReloaderService,
      private articlePriceService: ArticlePurchasePriceService,
      loadingService: LoadingService
   ) {
      super(apiReloader, loadingService);
      this.setLocation(['article', 'part']);
   }

   getSuppliers(articles: Article[]) {
      let suppliersList = [];
      articles.forEach(article => {
         if (
            suppliersList.findIndex(
               supplier => article.supplier === supplier
            ) === -1
         ) {
            suppliersList = [...suppliersList, article.supplier];
         }
      });
      return suppliersList;
   }


  getJoinableElements(): string[] {
    return [
      'pictures',
      'supplier',
      'saleUnit'
    ];
  }

  /**
   * Attention, cette api ne va pas envoyer un article qui est archivé.
   * Si on veut trouver un article archivé, il faut requêter sur
   * https://app.blgcloud.com/deltaforce/api/article/part/all/10095?join=[salePrices.salePriceType,pictures]
   */
  public async getById(id: number): Promise<Page> {
    const query = {
      filter: {
        id: {
          $in: [id],
        },
      },
      join: this.getJoinableElements(),
    };
    const res = await this.list(query);
    // console.log('res', res);
    if (res.length === 0) {
      return null;
    }
    return await res[0];
  }


  public async getByIdForPdf(id: number): Promise<Article> {
    const query = {
      filter: {
        id: {
          $in: [id],
        },
      },
      join: this.getJoinableElements(),
      field: ['id', 'pictures', 'supplier', 'label', 'comment', 'reference', 'customFields',
        'saleUnit', 'salePackaging', 'weight']
    };
    const res = await this.list(query);
    // console.log('res', res);
    if (res.length === 0) {
      return null;
    }
    return await res[0];
  }

  public async getList(query: any) {
      const thisQuery = {
        join: ['supplier', 'pictures'],
        ...query,
        field: ['id']
      };
      return await this.list(thisQuery);
   }

   public async getPrice(id: number) {
      const thisQuery = {
        join: ['salePrices.salePriceType','supplierPrices'],
        field: ['salePrices','supplierPrices'],
        filter: {
          id: id,
          /*salePrices : {
            salePriceType : {
              name : 'customerPrice'
            }
          }*/
        },
      };
      return await this.list(thisQuery);
   }

   public async getPriceToPublish(id: number){
     let priceToPublish = 0
     let publicPrice = 0;
     const prices = await this.articlePriceService.getPrice(id);
     let purchasePrice = 0;
     for(const price of prices){
       purchasePrice = price.purchasePrice;
     }
     const salesPrices = await this.getPrice(id);
     for (const elem of salesPrices) {
       for (const salePrice of elem.salePrices.filter( (prix) => (prix.salePriceType.name === 'publicPrice'))) {
         if (salePrice.policy === 'purchasePriceXCoefficient') {
           if (salePrice.fixedPrice) {
             priceToPublish = salePrice.fixedPrice;
           } else {
             priceToPublish = purchasePrice * salePrice.coefficient;
           }
         }else{
           priceToPublish = salePrice.fixedPrice;
         }
         publicPrice = priceToPublish;
       }
       for (const salePrice of elem.salePrices.filter( (prix) => (prix.salePriceType.name === 'customerPrice'))) {
         if (salePrice.policy === 'purchasePriceXCoefficient') {
           if (salePrice.fixedPrice) {
             priceToPublish = salePrice.fixedPrice;
           } else {
             priceToPublish = purchasePrice * salePrice.coefficient;
           }
         }else if(salePrice.policy === 'publicPriceX1') {
            // Prix public * 1
            // todo, voir comment est ecrit cette policy, car je n'ai pas trouvé l'exemple
            priceToPublish = publicPrice;
         }else{
           priceToPublish = salePrice.fixedPrice;
         }
       }
     }
     return Math.round(priceToPublish * 100) / 100 ;
   }

   async filterByTag(
      tag: string,
      limit: number,
      search: string,
      supplier: number,
      additionalTags: any[]
   ): Promise<Article[]> {
      const additionalTagsOptions = additionalTags.map(tagVal => ({
         tag: {
            $in: [tagVal.name],
         },
      }));
      const query = {
         filter: {
            id: null,
            supplier: null,
            tag: null,
            $and: null,
         },
         limit,
         order: ['label', 'asc'],
         join: ['supplier', 'pictures'],
         field: ['id', 'pictures', 'supplier', 'label', 'comment', 'reference', 'customFields']
      };

      if (additionalTagsOptions.length > 0) {
         query.filter.$and = [
            { tag: { $in: [tag] } },
            ...additionalTagsOptions,
         ];
         delete query.filter.tag;
      } else {
         query.filter.tag = { $in: [tag] };
         delete query.filter.$and;
      }
      if (supplier && supplier !== 0) {
         query.filter.supplier = {
            id: supplier,
         };
      } else {
         delete query.filter.supplier;
      }
      if (search.length < 3) {
        delete query.filter.id;
        return new Promise((resolve, reject) => {
          this.list(query).then((res: Article[]) => {
             res.forEach(article => {});
             resolve(res);
          });
        });
      } else {
        // Récupération d'une liste via search
        delete query.filter.id;
        const resultSearch: Article[] =  await new Promise((resolve, reject) => {
            this.search(search, query).then((res) => {
               resolve(res.map( (elem) => {
                 const score = elem.score;
                 elem = elem.data;
                 elem.score = score;
                 return elem;
               } ));
            });
         });
         // Ajout des id dans le filtre
        query.filter.id = {
          $in: [...resultSearch.map((article: Article) => article.id)]
        };
        // Execution de la fonction list pour récupérer les images ( faire des jointures )
        return new Promise((resolve, reject) => {
          this.list(query).then((res: Article[]) => {
            for ( const art of res ) {
              for ( const artSearch of resultSearch ) {
                if ( art.id === artSearch.id ) {
                  art.score = artSearch.score;
                }
              }
            }
            res.sort((a, b) => {
              return (a.score > b.score) ? -1 : ((b.score < a.score) ? 1 : 0);
            });
            resolve(res);
          });
        });

      }
   }
}
