import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {AnimateurCompanie} from '../refs/animateur-companie';
import {AnimateurService} from '../services/animateur.service';
import {CompaniesService} from '../services/companies.service';
import {AnimateurCompanieService} from '../services/animateur-companie.service';

@Component({
  selector: 'pm-df-animateurs',
  templateUrl: './animateurs.component.html',
  styleUrls: ['./animateurs.component.css']
})
export class AnimateursComponent implements OnInit {

  public dataSource: MatTableDataSource<AnimateurCompanie>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public isLoading = false;
  constructor(
    private animateursCompanieService: AnimateurCompanieService,
    private animateursService: AnimateurService,
    private companieService: CompaniesService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.getTable();
  }

  async getTable() {
    let data = await this.animateursCompanieService.getList();
    const companies = await this.companieService.getList();
    data = data.map(row => {
        const company = companies.find((p) => row.companieId === p.id);
        return { ...row, company : company };
    });
    data.sort((a, b) => (a.company?.companyName > b.company?.companyName && 1) || -1);
    this.dataSource = new MatTableDataSource<AnimateurCompanie>(data);
    console.log(data);
  }

  updateTable() {
    this.isLoading = true;
    this.animateursService.getAnimateursParZone().then(()=> {
      this.animateursCompanieService.getList().then(AnimateursCompanies => {
        this.companieService.getList().then( async (companies) => {
          const Control = [];
          for(const company of companies){
              if(this.animateursService.animateurs[company.id]){
                for(const a of this.animateursService.animateurs[company.id]){
                  let AnimateurC = AnimateursCompanies.find((p) => {
                    return p.companieId === company.id && p.nom === a.firstName + ' ' + a.lastName;
                  } );
                  if(AnimateurC === undefined){
                    AnimateurC = new AnimateurCompanie();
                    AnimateurC.company = company;
                    AnimateurC.companieId = company.id;
                    AnimateurC.nom = a.firstName + ' ' + a.lastName;
                    AnimateurC.descripteur = a.id + company.id;
                    AnimateurC.animateurId = a.id;
                    await this.animateursCompanieService.create(AnimateurC);
                  }else{
                    AnimateurC.company = company;
                    AnimateurC.companieId = company.id;
                    AnimateurC.nom = a.firstName + ' ' + a.lastName;
                    AnimateurC.descripteur = a.id + company.id;
                    AnimateurC.animateurId = a.id;
                    await this.animateursCompanieService.update(AnimateurC);
                  }
                  Control.push({
                    nom: a.firstName + ' ' + a.lastName,
                    companieId: company.id
                  })
                }
              }
          }
          for(const animateur of AnimateursCompanies){
            const test = Control.find((p) => p.companieId === animateur.companieId && p.nom === animateur.nom);
            if(test === undefined){
              await this.animateursCompanieService.delete(animateur.id);
            }
          }
          this.getTable();
          this.isLoading = false;
        });
      });
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
