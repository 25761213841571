<div>
    <mat-form-field>
        <input
                matInput
                (keyup)="applyFilter($event.target.value)"
                placeholder="{{ 'FILTER' | translate }}"
        />
    </mat-form-field>
</div>
<table mat-table [dataSource]="dataSource" class="full-width-table">
    <!-- Title Column -->
    <ng-container matColumnDef="label">
        <th mat-header-cell *matHeaderCellDef>Nom</th>
        <td mat-cell *matCellDef="let element">{{ element?.label }}</td>
    </ng-container>

    <!-- Nb Mandatory Column -->
    <ng-container matColumnDef="typeFormat">
        <th mat-header-cell *matHeaderCellDef>Type de Format </th>
        <td mat-cell *matCellDef="let element">{{ element?.typeFormat }}</td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let element">
            <button
                    mat-icon-button
                    (click)="edit(element)"
                    *ngIf="!element.isUsed"
            >
                <mat-icon>edit</mat-icon>
            </button>
            <button
                    mat-icon-button
                    (click)="delete(element)"
                    *ngIf="!element.isUsed"
            >
                <mat-icon>delete</mat-icon>
            </button>
        </td>
    </ng-container>

    <tr
            mat-header-row
            *matHeaderRowDef="[
         'label',
         'typeFormat',
         'actions'
      ]"
    ></tr>
    <tr
            mat-row
            *matRowDef="
         let row;
         columns: ['label', 'typeFormat', 'actions']
      "
    ></tr>
</table>

<div *ngIf="errorMessage" class="alert alert-danger">
    Error: {{ errorMessage }}
</div>

<button (click)="addFormat()" mat-fab class="add-button">
    <mat-icon>add</mat-icon>
</button>
