import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanLoad,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  Route, ActivatedRoute,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { Role } from '../refs/role';
import { DFApiService } from '../services/dfapi.service';
import { DFApiReloaderService } from '../services/dfapi-reloader.service';
import { LoadingService } from '../services/loading.service';

import { User } from '../refs/user';

import { environment } from '../../environments/environment';

export class MyCustomEvent extends CustomEvent<any> {
   public data: any;

   constructor(type, data) {
      super(type, {});
      this.data = data;
   }
}

@Injectable({
   providedIn: 'root',
})
export class AuthGuard extends DFApiService implements CanActivate, CanLoad {
   previousLocation='';
   constructor(
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private customAuthService: AuthService,
      apiReloader: DFApiReloaderService,
      loadingService: LoadingService
   ) {
      super(apiReloader, loadingService);
      this.setAuthService(customAuthService);
      this.setLocation(['user', 'user']);
   }

   canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
   ):
      | Observable<boolean | UrlTree>
      | Promise<boolean | UrlTree>
      | boolean
      | UrlTree {
      const expectedRole = route.data.expectedRole;
      if(this.authService.token){
        const doc = window;
        const evtName = 'message'; // 'userConnected';
        const eventHandlerTito = (event: MyCustomEvent) => {
          doc.removeEventListener(evtName, eventHandlerTito);
        }
      }
      if (!this.authService.isLoggedIn) {
         return new Promise((resolve, reject) => {
            const doc = window;
            const evtName = 'message'; // 'userConnected';
            const eventHandler = (event: MyCustomEvent) => {
               if (
                  (event.target as Window).location.origin !==
                     document.location.origin ||
                  event.data.type !== 'userConnected'
               ) {
                  return;
               }
               this.authService.setToken(event.data.token);
               doc.removeEventListener(evtName, eventHandler);
               this.init();
               this.checkCredentials().then((credentials: User) => {
                  const query = {
                     filter: {
                        username: { $in: [credentials.username] },
                     },
                     join: ['contact', 'company', 'userGroups.group', 'rightCompanies.company'],
                  };
                  this.list(query).then((res) => {
                     if (res.length !== 1) {
                        reject(new Error('Failed logging in. UTILISATEUR INEXISTANT : ' + credentials.username));
                     }
                     const user = res[0];
                     const role = this.getRoleFromLoginResult(user);
                     if (role) {
                        credentials.fullname =
                           user.contact.firstName + ' ' + user.contact.lastName;
                        credentials.mail = user.mail;
                        credentials.role = role;
                        credentials.company = user.company;
                        credentials.mail = user.mail;
                        credentials.crmId = user.contact.crmId;
                        // On se base sur les droits de l'utilisateur pour déterminer les sociétés auxquelles il a accès
                        credentials.relatedCompanies = user.rightCompanies.map( (rc) => rc.company);
                       this.authService.login(credentials);
                        this.apiReloader.setAuthService(this.authService);

                        if(event.data.location){
                           const locationIFrame= JSON.parse(event.data.location)
                           if(locationIFrame.search){
                             const parameters = decodeURIComponent(locationIFrame.search.substr(1)).split('&');
                             const parametersGET=[];
                             for(const parameter of parameters){
                               const parameterValue = parameter.split('=');
                               parametersGET[parameterValue[0]]= parameterValue[1];
                             }
                             if(parametersGET['location']){
                                // if(this.previousLocation !==parametersGET['location'] ){
                                 this.previousLocation = parametersGET['location'];
                                 resolve(this.router.parseUrl(parametersGET['location']));
                                 // this.router.navigateByUrl(parametersGET['location']);
                                // }
                             }
                           }
                        }
                        this.authService.afterLogin.next(user);
                        resolve(this.isRoleValid(expectedRole));
                     } else {
                        reject(new Error('Failed logging in. PAS DE RÔLE POUR CET UTILISATEUR : '
                          + user.contact.firstName + ' ' + user.contact.lastName));
                     }
                  });
               });
            };

            doc.addEventListener(evtName, eventHandler);

            if (!environment.production) {
               let token = route.queryParams.token;
               if (token === undefined) {
                 token = environment.blg_extranet_auth.token;
               }
               window.postMessage(
                  {
                     type: 'userConnected',
                     token: token,
                  },
                  '*'
               );
            }
         });
      }
      return this.isRoleValid(expectedRole);
   }


  getRoleFromLoginResult(user) {
    let role = null;
    user.userGroups.forEach((element) => {
      switch (element.group.name) {
        case 'admin':
          role = Role.ROLE_ADMIN;
          break;

        case 'extranetCatalogAgency':
          role = Role.ROLE_AGENCY;
          break;

        case 'extranetCatalogMember':
          role = Role.ROLE_MEMBER;
          break;

        case 'extranetCatalogRouting':
          role = Role.ROLE_ROUTING_PRINTING;
          break;

        case 'saleManager':
          role = Role.ROLE_ANIMATOR;
          break;
      }
    });
    return role;
  }

   canLoad(route: Route): boolean {
      return this.checkLoggedIn(route.path);
   }

   getRoleChildren(role: Role) {
      switch (role) {
         case Role.ROLE_ADMIN:
            return [
               Role.ROLE_MEMBER,
               Role.ROLE_AGENCY,
               Role.ROLE_ANIMATOR,
               Role.ROLE_ROUTING_PRINTING,
            ];
            break;
         case Role.ROLE_ANIMATOR:
            return [
               Role.ROLE_MEMBER,
               Role.ROLE_AGENCY,
               Role.ROLE_ROUTING_PRINTING,
            ];
            break;
         case Role.ROLE_MEMBER:
            return [Role.ROLE_ROUTING_PRINTING];
            break;
         case Role.ROLE_AGENCY:
            return [Role.ROLE_ROUTING_PRINTING];
            break;
         case Role.ROLE_ROUTING_PRINTING:
            return [];
            break;
      }
      return [];
   }

   isRoleParentOf(userRole: Role, expectedRole: Role) {
      return this.getRoleChildren(userRole).indexOf(expectedRole) !== -1;
   }

   isRoleValid(role: Role): boolean {
      if (!role) {
         return true;
      }
      if (!this.authService.isLoggedIn) {
         return false;
      }
      const user = this.authService.currentUser;

      if (user.role === Role.ROLE_ADMIN) {
         return true;
      }
      if (user.role === role || this.isRoleParentOf(user.role, role)) {
         return true;
      }
      return false;
   }

   checkLoggedIn(url: string): boolean {
      if (this.authService.isLoggedIn) {
         return true;
      }
      this.authService.redirectUrl = url;
      this.router.navigate(['/login']);
      return false;
   }
}
