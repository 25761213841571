<div class="container">
   <mat-card class="example-card" *ngIf="article?.article?.pictures !== undefined">
      <img
         *ngIf="article?.article?.pictures[0]"
         [src]="
            urlBase + 'api/image/' +
            (article?.article?.pictures)[0]?.picture +
            '/smallGallery'
         "
         alt="{{ (article?.article?.pictures)[0]?.picture }}"
         class="article-image"
      />
      <div class="item"     [ngClass]="{ noimage: !article?.article?.pictures[0] }">
         <h5>{{ article?.article?.label | uppercase }}</h5>
         <h6 class="details">
            Fournisseur : {{ article?.article?.supplier?.title }}
         </h6>
         <h6 *ngIf="article?.article?.reference">Réf: {{ article?.article?.reference }}</h6>
      </div>
      <mat-checkbox
         class="favorite"
         color="primary"
         disableRipple="true"
         [(ngModel)]="article.favorite"
         (ngModelChange)="onArticleChange()"
         ><mat-icon
            *ngIf="article.favorite"
            class="star md-48"
            aria-hidden="false"
            aria-label="favorite"
            [inline]="true"
            >star</mat-icon
         >
         <mat-icon
            *ngIf="!article.favorite"
            class="star-border"
            aria-hidden="false"
            aria-label="favorite"
            [inline]="true"
            >star_border</mat-icon
         >
      </mat-checkbox>
   </mat-card>
   <mat-card class="example-card" *ngIf="article?.article?.pictures === undefined">
      <div class="item"     [ngClass]="{ noimage: !article?.article?.pictures  === undefined }">
         <h5>{{ article?.article?.label | uppercase }}</h5>
         <h6 class="details">
            Fournisseur : {{ article?.article?.supplier?.title }}
         </h6>
         <h6 *ngIf="article?.article?.reference">Réf: {{ article?.article?.reference }}</h6>
      </div>
      <mat-checkbox
         class="favorite"
         color="primary"
         disableRipple="true"
         [(ngModel)]="article.favorite"
         (ngModelChange)="onArticleChange()"
         ><mat-icon
            *ngIf="article.favorite"
            class="star md-48"
            aria-hidden="false"
            aria-label="favorite"
            [inline]="true"
            >star</mat-icon
         >
         <mat-icon
            *ngIf="!article.favorite"
            class="star-border"
            aria-hidden="false"
            aria-label="favorite"
            [inline]="true"
            >star_border</mat-icon
         >
      </mat-checkbox>
   </mat-card>
</div>
