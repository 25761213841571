import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {Article} from '../refs/article';
import {Format} from '../refs/format';
import {Page} from '../refs/page';
import {CampagneType} from '../refs/campagne-type';

@Injectable({
  providedIn: 'root',
})
export class UpdateService {

  private draft = new Subject<boolean>();
  private format = new Subject<Format>();
  private page = new Subject<Page>();
  private titleCustom = new Subject<any>();
  private titleUpdate = new Subject<any>();

  private tag = new Subject<boolean>();
  private articlesList = new Subject<Article[]>();
  private campagneTypeUpdate = new Subject<CampagneType>();

  articlesList$ = this.articlesList.asObservable();
  draft$ = this.draft.asObservable();
  tag$ = this.tag.asObservable();
  format$ = this.format.asObservable();
  page$ = this.page.asObservable();
  titleCustom$ = this.titleCustom.asObservable();
  titleUpdate$ = this.titleUpdate.asObservable();
  campagneTypeUpdate$ = this.campagneTypeUpdate.asObservable();


  // public draft: boolean;
  constructor() { }

  setDraft(draft) {
    this.draft.next(draft);
  }

  setTag(tag) {
    this.tag.next(tag);
  }

  setArticlesList(articlesList) {
    this.articlesList.next(articlesList);
  }
  setFormat(format) {
    this.format.next(format);
  }
  setPage(page) {
    this.page.next(page);
  }
  setTitleCustom(title) {
    this.titleCustom.next(title);
  }
  setTitleUpdate(update: boolean) {
    this.titleUpdate.next(update);
  }
  setCampagneTypeUpdate(campagneType: CampagneType) {
    this.campagneTypeUpdate.next(campagneType);
  }
}
