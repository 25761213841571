import {Format} from '../refs/format';
import {Label} from '../refs/label';
import {Title} from '../refs/title';

import {SlugifyService} from '../shared/slugify.service';
import {AnimateurCompanie} from '../refs/animateur-companie';
import {MailLog} from '../refs/mail-log';
import {CampagneType} from '../refs/campagne-type';

export class Config {
   id: number;
   type = '';
   name = '';
   data: any = null;

   static fromLocal(
      object: any,
      type: string,
      slugifyService: SlugifyService
   ): Config {
      switch (type) {
         case 'label':
            return {
               id: object.id,
               type: type,
               name: slugifyService.do(slugifyService.noAccent(object.title)),
               data: {
                  title: object.title,
                  picture: object.picture,
                  company: object.company
               },
            };
            break;

         case 'animateurcompanie':
            return {
               id: object.id,
               type: type,
               name: slugifyService.do(slugifyService.noAccent(object.descripteur)),
               data: {
                  descripteur: object.descripteur,
                  nom: object.nom,
                  companieId: object.companieId,
                  animateurId: object.animateurId,
               },
            };
            break;
        case 'maillog':
            // j'ai pas trouvé un autre moyen de créer un id...
            // esperons qu'il y a pas deux personnes qu'envoyent un mail au meme minute
            let id = new Date().getTime();
            if(object.mail && object.mail.id){
              id = object.mail.id;
            }
            return {
               id: id,
               type: type,
               name: slugifyService.do(slugifyService.noAccent(id + ' ' + object.adherentId)),
               data: {
                 mailType: object.mailType,
                 adherentId: object.adherentId,
                 adherentName: object.adherentName,
                 catalogId: object.catalogId,
                 creator: object.creator,
                 date: object.date,
                 to: object.to,
                 mail: object.mail,
                 commentaire: object.commentaire,
               },
            };
            break;

         case 'title':
            return {
               id: object.id,
               type: type,
               // name: slugifyService.do(object.label),
               name: slugifyService.slugifyTitle(object.label),
               data: {
                  label: object.label,
                  category: object.category,
               },
            };
            break;

         case 'format':
            return {
               id: object.id,
               type: type,
               name: slugifyService.slugifyTitle(object.title + '_' + object.nbPages),
               data: {
                  title: object.title,
                  grammage: object.grammage,
                  nbPages: object.nbPages,
                  nbMandatory: object.nbMandatory,
                  nbThematique: object.nbThematique,
                  isUsed: object.isUsed,
                  // description: object.description,
                  pageConcession: object.pageConcession,
                  nbConcession: object.nbConcession,
                  nbLibre: object.nbLibre,
                  sansCouv: object.sansCouv,
                  campagneType: object.campagneType,
                  pageAdresses: object.pageAdresses,
               },
            };
            break;
      }
      return {
         id: object.id,
         type: type,
         name: object.label,
         data: null,
      };
   }

   static toLocal(config: Config): Format | Label | Title | AnimateurCompanie | MailLog {
      switch (config.type) {
         case 'label':
            return {
               id: config.id,
               title:
                  config.data && config.data.title ? config.data.title : null,
               picture:
                  config.data && config.data.picture
                     ? config.data.picture
                     : null,
            } as Label;
            break;
         case 'animateurcompanie':
            return {
               id: config.id,
               descripteur:
                  config.data && config.data.descripteur ? config.data.descripteur : null,
               nom: config.data && config.data.nom ? config.data.nom : null,
               companieId: config.data && config.data.companieId ? config.data.companieId : null,
               animateurId: config.data && config.data.animateurId ? config.data.animateurId : null,
               company: config.data && config.data.company ? config.data.company : null,
            } as AnimateurCompanie;
            break;
        case 'maillog' :
          return {
            id: config.id,
            mailType: config.data && config.data.mailType ? config.data.mailType : null,
            adherentId: config.data && config.data.adherentId ? config.data.adherentId : null,
            adherentName: config.data && config.data.adherentName ? config.data.adherentName : null,
            catalogId: config.data && config.data.catalogId ? config.data.catalogId : null,
            creator: config.data && config.data.creator ? config.data.creator : null,
            date: config.data && config.data.date ? config.data.date : null,
            mail: config.data && config.data.mail ? config.data.mail : null,
            commentaire: config.data && config.data.commentaire ? config.data.commentaire : null,
          } as MailLog;
          break
         case 'title':
            return {
               id: config.id,
               label:
                  config.data && config.data.label ? config.data.label : null,
               category:
                  config.data && config.data.category
                     ? config.data.category
                     : null,
            } as Title;
            break;
         case 'format':
            return {
               id: config.id,
               title:
                  config.data && config.data.title ? config.data.title : null,
               campagneType: config.data && config.data.campagneType ? config.data.campagneType : CampagneType.DEFAUT,
               grammage: config.data && config.data.grammage ? config.data.grammage : null,
               sansCouv: config.data && config.data.sansCouv ? config.data.sansCouv : false,
               nbPages:
                  config.data && config.data.nbPages ? config.data.nbPages : 0,
               nbMandatory:
                  config.data && config.data.nbMandatory
                     ? config.data.nbMandatory
                     : 0,
               nbLibre:
                  config.data && config.data.nbLibre
                     ? config.data.nbLibre
                     : 0,
               nbThematique:
                   config.data && config.data.nbThematique
                       ? config.data.nbThematique
                       : 0,
               pageConcession:
                   config.data && config.data.pageConcession
                       ? config.data.pageConcession
                       : 0,
               nbConcession:
                   config.data && config.data.nbConcession
                        ? config.data.nbConcession
                        : 0,
               pageAdresses:
                    config.data && config.data.pageAdresses
                        ? config.data.pageAdresses
                        : false,
               isUsed:
                  config.data && config.data.isUsed
                     ? config.data.isUsed
                     : false,
               // description:
               //    config.data && config.data.description
               //       ? config.data.description
               //       : null,
            } as Format;
            break;
      }
      return null;
   }

  static CreateGuid() {
    const _p8 = (s) => {
      const p = (Math.random().toString(16)+'000000000').substr(2,8);
      return s ? '-' + p.substr(0,4) + '-' + p.substr(4,4) : p ;
    }
    return _p8(false) + _p8(true) + _p8(true) + _p8(false);
  }
}
