import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {ProductsService} from '../../services/products.service';
import {ArticleSuppliersService} from '../../services/article-suppliers.service';
import {ArticlePictureService} from '../../services/article-picture.service';
import {Article} from '../../refs/article';
import {Campagn} from '../../refs/campagn';
import {DisplaySelectedObjectService} from '../../services/display-selected-object.service';
import {PageElementService} from '../../services/cat/page-element.service';
import {FieldResolverService} from '../../services/field-resolver.service';
import {TagsService} from '../../services/tags.service';

import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Page} from '../../refs/page';

import {PageService} from '../../services/cat/page.service';
import {UpdateService} from '../../services/update.service';
import {Supplier} from '../../refs/supplier';
import {ArticleDroppedService} from '../../services/articleDropped.service';
import {Catalog} from '../../refs/catalog';
import {PageType} from '../../refs/page-type';


export class ArticleView {
   public article: Article;
   public favorite: boolean;
}

export class ArticleDataSource extends DataSource<Article | undefined> {
   private initialData: Article[] = [];
   private dataStream = new BehaviorSubject<(Article | undefined)[]>(
      this.initialData
   );
   public articles: Article[] = [];
   public unfiltered = true; // Variable pour savoir que l'user n'a pas mis de filtre
   private subscription = new Subscription();
   private filter = '';
   private limit = 30;
   private supplier = 0;
   private additionalTags = [];

   constructor(
      private productsService: ProductsService,
      private articlePicturesService: ArticlePictureService,
      private campagn: Campagn
   ) {
      super();
   }
   connect(
      collectionViewer: CollectionViewer
   ): Observable<(Article | undefined)[]> {
      this.subscription.add(
         collectionViewer.viewChange.subscribe(() => {
            this.updateList();
         })
      );
      return this.dataStream;
   }

   updateFilter(text: string, supplier: any, additionalTags: any) {
      this.supplier = parseInt(supplier, 10); // Le template passe le 0 comme "0"
      this.filter = text;
      this.articles = [];
      this.additionalTags = additionalTags;
      if ( this.supplier === 0 &&
           this.filter === '' &&
           this.additionalTags.length === 0) {
        this.unfiltered = true;
        this.articles = [];
        this.formatData([]);
      } else {
        this.unfiltered = false;
        this.limit = 200;
        this.updateList();

      }
   }

   updateList() {
      if (this.campagn.tag && this.campagn.tag !== '') {
        if ( !this.unfiltered ) {
          this.productsService
            .filterByTag(
              this.campagn.tag,
              this.limit,
              this.filter,
              this.supplier,
              this.additionalTags
            )
            .then(data => {
              this.formatData(data);
            });
        }
      }
   }

   disconnect(): void {
      this.subscription.unsubscribe();
   }
   formatData(_body: Article[]): void {
      this.articles = _body;
      this.dataStream.next(this.articles);
   }

   getDataStream() {
      return this.dataStream;
   }
}

@Component({
   selector: 'pm-df-article-list',
   templateUrl: './article-list.component.html',
   styleUrls: ['./article-list.component.css'],
})
export class ArticleListComponent implements OnInit {
   @Input() campagnId: number;
   @Input() campagn: Campagn;
   @Input() catalog: Catalog;
   @Input() allowProductsFromAllPages = false;

   page: Page;
   searchText = '';
   supplier = 0;

   suppliers: Supplier[] = [];
   selectedSuppliers: Supplier[] = [];
   searchPresta = '';
   articles: Article[];
   articleViews: ArticleView[];
   articlesSource: ArticleDataSource;
   articlesOnPages: Article[] = [];
   selectedTags: any[] = [];
   tags: any[];
   filteredTags: any[];
   separatorKeysCodes = [ENTER, COMMA];

   constructor(
      private selectedObjectService: DisplaySelectedObjectService,
      private productsService: ProductsService,
      private articlePicturesService: ArticlePictureService,
      private articleSuppliersService: ArticleSuppliersService,
      private cdr: ChangeDetectorRef,
      private pageElementService: PageElementService,
      private pageService: PageService,
      private fieldResolver: FieldResolverService,
      private tagsService: TagsService,
      private updateService: UpdateService,
      private articleDropped: ArticleDroppedService
   ) {
      updateService.tag$.subscribe(t => { this.initPage(); });
      updateService.articlesList$.subscribe(a => {
         this.articles = a;
         this.articleViews = a
             .map(article => {
                return {
                   article,
                   favorite:
                       this.page &&
                       this.page.basket.findIndex(fav => fav.id === article.id) !==
                       -1,
                };
             });
      });
   }

   ngOnInit() {
      this.initPage();
      this.articleDropped.articleDroppedSubject.subscribe(() => {
        this.restartFilter();
        this.onSearchTextChange();
      })
     if(this.catalog){
       this.catalog.pages.map( page => {
         if(page.type !== PageType.COVER){
           // Le produit de la couverture peut etre utilisé dans les autres pages.
           for ( const product in page.productsOnPage) {
             if (product) {
               this.articlesOnPages = [... this.articlesOnPages, page.productsOnPage[product].article];
             }
           }
         }
       });
     }else{
       this.campagn.pages.map( page => {
         for ( const product in page.productsOnPage) {
           if (product) {
             this.articlesOnPages = [... this.articlesOnPages, page.productsOnPage[product].article];
           }
         }
       });
     }
   }

   initPage() {
      this.articlesSource = new ArticleDataSource(
          this.productsService,
          this.articlePicturesService,
          this.campagn
      );
      this.tagsService.getList().then(tags => {
         this.tags = tags;
         this.filterTags('');
      });
      this.articlesSource.getDataStream().subscribe(data => {
         this.articles = data;
         if(this.allowProductsFromAllPages === false){
           if(this.page && this.page.catalog){
             if (this.articlesOnPages !== null) {
               for (const product in this.articlesOnPages) {
                 if (product) {
                   // aca!
                   this.articles = this.articles.filter( a => {
                     return a.id !== this.articlesOnPages[product].id
                   });
                 }
               }
             }
           }
         }
         this.updateFavoriteArticles();
      });
      // Si on change de page, on remet les donnes en place.
      this.selectedObjectService.selectedPage$.subscribe(async page => {
         this.page = page;
         if (this.page) {
           this.page.basket = await this.fieldResolver.resolveField(
             this.page.basket,
             article => article,
             (oldA, newA) => newA,
             this.productsService
           );
           this.updateFavoriteArticles();
           this.searchText = '';
           this.supplier = 0;
           this.onSearchTextChange();
         }
      });
      this.articlesSource.updateList();
      if (this.campagn.tag && this.campagn.tag !== '') {
         this.articleSuppliersService
             .getList(this.campagn.tag)
             .then(data => {
               this.suppliers = data;
               this.selectedSuppliers = this.search('');
             });
      }
   }
   updateFavoriteArticles() {
      this.articleViews = this.articles
         .map(article => {
            return {
               article,
               favorite:
                  this.page &&
                  this.page.basket.findIndex(fav => fav.id === article.id) !==
                     -1,
            };
         })
         .sort((article1, article2) => {
            if (article1.favorite && !article2.favorite) {
               return -1; // put article 1 first
            } else if (article2.favorite && !article1.favorite) {
               return 1; // put article 2 first
            }
            return 0;
         });
      this.cdr.markForCheck();
   }

   onFavoriteStateChange(article: ArticleView) {
      if (article.favorite) {
         this.page.basket = [...this.page.basket, article.article];
         this.pageService.update(this.page);
      } else {
         this.page.basket = this.page.basket.filter(
            art => article.article.id !== art.id
         );
         this.pageService.update(this.page);
      }
      this.updateFavoriteArticles();
   }

   onSearchTextChange() {
      const present = false;
      if (!present || this.searchText === '') {
         this.articlesSource.updateFilter(
             this.searchText,
             this.supplier,
             this.selectedTags
         );
      }
      this.searchPresta = '';
      this.filterPrestatairesList();

   }
  restartFilter() {
     if ( this.searchText !== ''){
       this.searchText = '';
       if(!this.supplier){
         this.articles = [];
       }
     }
  }/*
  restartFilter(event: CdkDragDrop<string[]>) {
     if ( this.searchText !== ''){
       this.searchText = '';
       if(!this.supplier){
         this.articles = [];
       }
     }
  }*/
  // Receive user input and send to search method**
  filterPrestatairesList() {
     this.selectedSuppliers = this.search(this.searchPresta);
  }

  // Filter the states list and send back to populate the selectedStates**
  search(value: string) {
    if(value === ''){
      return this.suppliers;
    }else{
      const filter = value.toLowerCase();
      return this.suppliers.filter(option => option.title.toLowerCase().includes(filter));
    }
  }
  /**
   * L'utisateur a fait drag un drop d'un produit.
   */
   drop(event: CdkDragDrop<string[]>) {
      if (event.previousContainer === event.container) {
         moveItemInArray(
            event.container.data,
            event.previousIndex,
            event.currentIndex
         );
      } else {
         if (event.item.data.article) {
            this.pageElementService.delete(event.item.data.id);
            this.articles = [...this.articles, event.item.data.article];
            // on retrie les articles par label
            // Sinon, l'article est mis au fond de la liste.
            this.articles.sort((a, b) => (a.label > b.label && 1) || -1);
            this.articleViews = this.articles
                .map(article => {
                   return {
                      article,
                      favorite:
                          this.page &&
                          this.page.basket.findIndex(fav => fav.id === article.id) !==
                          -1,
                   };
                });
         }
         let previousIndex = 0;
         for( const pageElement of event.previousContainer.data){
           // @ts-ignore
           if(pageElement.article.id === event.item.data.article.id){
             break;
           }
           previousIndex++;
         }
         event.previousContainer.data.splice(previousIndex, 1);
      }
   }

   removeTag(tagName: string): void {
      this.selectedTags = this.selectedTags.filter(tag => tag.name !== tagName);
   }

   selectedTag(event: any): void {
      const tag = this.tags.find(tagVal => event.option.value === tagVal.name);
      this.selectedTags.push(tag);
      this.onSearchTextChange();
   }

   autocompleteChange(event: any) {
      this.filterTags(event);
   }

   filterTags(filter: string) {
      if (filter.length > 2) {
         this.filteredTags = this.tags.filter(tag => {
            const regex = new RegExp(filter, 'i');
            return tag.title.match(regex);
         });
      } else {
         this.filteredTags = this.tags;
      }
   }
}
