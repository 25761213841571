import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
   providedIn: 'root',
})
export class LoadingService {
   loadingValue = true;
   public loadingEventEmitter = new EventEmitter<boolean>();

   public get loading() {
      return this.loadingValue;
   }

   public set loading(val: boolean) {
      this.loadingValue = val;
      this.loadingEventEmitter.emit(this.loadingValue);
   }
}
