import { Injectable } from '@angular/core';

import { DFApiService } from './dfapi.service';
import { DFApiReloaderService } from './dfapi-reloader.service';
import { LoadingService } from './loading.service';

import {Supplier} from '../refs/supplier';

@Injectable({
   providedIn: 'root',
})
export class ArticleSuppliersService extends DFApiService {
   constructor(
      apiReloader: DFApiReloaderService,
      loadingService: LoadingService
   ) {
      super(apiReloader, loadingService);
      this.setLocation(['article', 'supplier']);
   }

   getUnique(array) {
      return array.filter((thing, index, self) =>
           index === self.findIndex((t) => (
             t.title === thing.title
           ))
       );
   }

  /**
   * Cette fonction fait une requete qui renvoie un resultat de 5 MB. En plus elle se fait deux fois.
   */
   getList(tag: string): Promise<Supplier[]> {
      const query = {
        filter: {
          articles: {
            tags: {
              tag: {
                name: {$in: [tag]}
              }
            }
          },
          active: true
        },
        join: ['company'],
        field : [
          'id',
          'title',
          'name',
          'companyId'
        ],
        order : ['title', 'asc']
      };

      return new Promise((resolve, reject) => {
         this.list(query).then((res: Supplier[]) => {
            resolve(res);
            // resolve(this.getUnique(res));
         });
      });
   }
}
