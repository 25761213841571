import { Injectable } from '@angular/core';

import ApiClient from '@blg/jsapilib/fetch';
import {environment} from '../../environments/environment';

// const API_PATH = 'https://app.blgcloud.com/deltaforce-test/api/';
// const API_PATH = 'https://app.blgcloud.com/deltaforce/api/';

import { AuthService } from '../user/auth.service';

import { DFApiReloaderService } from './dfapi-reloader.service';

import { LoadingService } from './loading.service';

const mimeDb = require('mime-db');

@Injectable({
   providedIn: 'root',
})
/**
 * Service pour consulter l'api de BLG
 */
export class DFApiService {
   private apiClient: ApiClient;
   private location: string[];
   protected authService: AuthService;
   protected urlBase = '';
   protected apiPath = '';

   constructor(
      protected apiReloader: DFApiReloaderService,
      protected loadingService: LoadingService
   ) {
      this.apiPath = environment.urlBase + 'api/'
      this.authService = new AuthService();
      this.init();
      this.location = [];
      this.apiReloader.add(this);
   }

   public setAuthService(authService: AuthService) {
      this.authService = authService;
      this.init();
   }

   public init() {
      this.apiClient = new ApiClient(this.apiPath, {
         language: 'fr_FR',
         token: this.authService.getToken(),
         fullResponse: false,
      });
   }

   public checkCredentials() {
      return this.apiClient.checkCredentialsDetailed();
   }

   public getPath() {
      return '/' + this.location.join('/');
   }

   public setLocation(location: string[]) {
      this.location = location;
   }

   public refreshApi() {
      if (this.authService.getToken() === '') {
         this.apiReloader.update();
      }
   }

   /**
    * Search for objects (SELECT / ElasticSearch)
    */
   public async search(text = '', query = {}) {
      this.refreshApi();

      const result = await this.apiClient.search(
         { q: text, ...query },
         {
            path: this.getPath(),
         }
      );
      return result;
   }

   /**
    * Get selected object (SELECT WHERE)
    */
   public async get(id: number) {
      this.refreshApi();
      this.loadingService.loading = true;
      const result = this.apiClient.getObject(this.location, id);
      this.loadingService.loading = false;
      return result;
   }

   /**
    * List the objects with filter (SELECT WHERE)
    */
   public async list(query = null) {
      this.refreshApi();
      const result = await this.apiClient.listObjects(this.location, query);
      return result;
   }

   /**
    * Patch the given object (UPDATE)
    */
   public patch(id, object) {
      this.refreshApi();
      return this.apiClient.patchObject(this.location, id, object);
   }

   /**
    * Put the given object (CREATE)
    */
   public put(object) {
      this.refreshApi();
      return this.apiClient.putObject(this.location, object);
   }

   /**
    * Delete the given object (DELETE)
    */
   public delete(id) {
      this.refreshApi();
      return this.apiClient.deleteObject(this.location, id);
   }

   public deleteOneObjectBy(filter) {
      this.refreshApi();
      return this.apiClient.deleteOneObjectBy(this.location, filter);
   }
  /**
   * Upload file
   *
   */
  public async putFileStream(file, filePath, location) {
    this.refreshApi();
    const result = await this.apiClient.putFileStream(file, filePath, location);
    return result;
  }



   /**
    * Send mail
    *
    */

   public sendMail(body) {
      this.refreshApi();
      this.apiClient.sendAnonymousMail(body);
   }

  getMimeTypeFromExtension(fileExtension: string) : string
  {
    for (const k in mimeDb) {  // const k: string
      if (mimeDb[k].extensions) {
        if(mimeDb[k].extensions.find( ( e ) => e === fileExtension)){
          return k;
        }
      }
    }
    return null;
  }



}
