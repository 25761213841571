import {Company} from './company';


export class AnimateurCompanie {
   id: number;
   descripteur: string;
   nom: string;
   companieId: number;
   animateurId: string;
   company: Company
}
