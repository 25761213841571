import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './page-not-found.component';
import { FormsModule } from '@angular/forms';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { PdfViewerModule } from 'ng2-pdf-viewer';

/* Feature Modules */
import { UserModule } from './user/user.module';
import { MaterialModule } from './material/material.module';
import { TitlesComponent } from './titles/titles.component';
import { TitleComponent } from './title/title.component';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { SharedModule } from './shared/shared.module';
import { FormatDialogComponent } from './format/format-dialog/format-dialog.component';
import { ValidationDialogComponent } from './validation-dialog/validation-dialog.component';
import { CampagnPageAddDialogComponent } from './campagn/campagn-page-add-dialog/campagn-page-add-dialog.component';

import { APP_BASE_HREF } from '@angular/common';
import { LabelsComponent } from './labels/labels.component';
import { LabelComponent } from './label/label.component';
import { ArticlePageDialogComponent } from './article/article-page-dialog/article-page-dialog.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ValidationCatalogDialogComponent } from './validation-catalog-dialog/validation-catalog-dialog.component';
import { TypeFormatComponent } from './type-format/type-format.component';
import { TypeFormatDialogComponent } from './type-format/type-format-dialog/type-format-dialog.component';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { UploadOneFileComponent } from './upload-one-file/upload-one-file.component';
import { RoutageDialogComponent } from './routage/routage-dialog/routage-dialog.component';
import { EncarteurDialogComponent } from './encarteur/encarteur-dialog/encarteur-dialog.component';
import { GestionComponent } from './gestion/gestion.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MailsDefComponent } from './services/mails/mails-def/mails-def.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import {ExecuterActionDialogComponent} from './executer-action-dialog/executer-action-dialog.component';
import { AnimateursComponent } from './animateurs/animateurs.component';

@NgModule({
   imports: [
      HttpClientModule,
      BrowserModule,
      BrowserAnimationsModule,
      FormsModule,
      MatSortModule,
      MatExpansionModule,
      SharedModule,
      UserModule,
      MaterialModule,
      AppRoutingModule,
      MatInputModule,
      MatTableModule,
      NgxFileDropModule,
      PdfViewerModule,
   ],
   declarations: [
      AppComponent,
      PageNotFoundComponent,
      TitlesComponent,
      TitleComponent,
      FormatDialogComponent,
      ValidationDialogComponent,
      ExecuterActionDialogComponent,
      AlertDialogComponent,
      CampagnPageAddDialogComponent,
      LabelsComponent,
      LabelComponent,
      ArticlePageDialogComponent,
      ValidationCatalogDialogComponent,
      TypeFormatComponent,
      TypeFormatDialogComponent,
      UploadFileComponent,
      UploadOneFileComponent,
      RoutageDialogComponent,
      EncarteurDialogComponent,
      GestionComponent,
      MailsDefComponent,
      AnimateursComponent,
   ],
   exports: [NgxDaterangepickerMd],
   schemas: [CUSTOM_ELEMENTS_SCHEMA],
   entryComponents: [
      TitleComponent,
      FormatDialogComponent,
      TypeFormatDialogComponent,
      ValidationDialogComponent,
      ExecuterActionDialogComponent,
      AlertDialogComponent,
      UploadFileComponent,
      UploadOneFileComponent,
      CampagnPageAddDialogComponent,
      ArticlePageDialogComponent,
      LabelComponent,
      ValidationCatalogDialogComponent,
      RoutageDialogComponent,
      EncarteurDialogComponent,
      MailsDefComponent
   ],
   bootstrap: [AppComponent],
   providers: [
      { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
      {
         provide: APP_BASE_HREF,
         useValue: '/client-specific/delta-force-catalogue/',
      },
   ],
})
export class AppModule {}
