import { Catalog } from './catalog';
import { Campaign } from './campaign';
import { PageElement } from './page-element';
import { Page as LocalPage } from '../refs/page';
import { ArticleOnPage } from '../refs/article-on-page';
import { Ref } from './ref';

import { SlugifyService } from '../shared/slugify.service';
import {PageFile} from './page-file';

export class Page {
   id: number;
   catalog?: Catalog | Ref = null;
   campaign?: Campaign | Ref = null;
   elements: (PageElement | Ref)[] = [];
   files?: any = null;
   data: any = null;
   pageComments: [];

   static toLocal(page: Page): LocalPage {
      let featuredArt = null;
      if ( page.data && page.data.featuredArticle && page.data.featuredArticle.length > 0 ) {
        featuredArt = page.data && page.data.featuredArticle ? page.data.featuredArticle : null;
      } else {
        featuredArt = null;
      }
      return {
         id: page.id,
         catalog: page.catalog
            ? Catalog.toLocal(page.catalog as Catalog)
            : null,
         campagn: page.campaign
            ? Campaign.toLocal(page.campaign as Campaign)
            : null,
         productsOnPage: page.elements
            ? page.elements.map(element =>
                 PageElement.toLocal(element as PageElement)
              )
            : [],
         files: page.files
           ? page.files.map( element => element)
           : [],
         title: page.data && page.data.title ? page.data.title : null,
         titleCustom: page.data && page.data.titleCustom ? page.data.titleCustom : null,
         noTitle: page.data && page.data.noTitle ? page.data.noTitle : false,
         type: page.data && page.data.type ? page.data.type : null,
         section: page.data && page.data.section ? page.data.section : null,
         order: page.data && page.data.order ? page.data.order : null,
         tags: page.data && page.data.tags ? page.data.tags : null,
         draft: page.data && page.data.draft ? page.data.draft : null,
         version: page.data && page.data.version ? page.data.version : null,
         commentCover: page.data && page.data.commentCover ? page.data.commentCover : null,
         commentInfoConcession: page.data && page.data.commentInfoConcession ? page.data.commentInfoConcession : null,
         preview: page.data && page.data.preview ? page.data.preview : null,
         titles: page.data && page.data.comment ? page.data.comment : null,
         basket: page.data && page.data.basket ? page.data.basket : null,
         logo: page.data && page.data.logo ? page.data.logo : null,
         // file: page.data && page.data.file ? page.data.file : null,
         // fileRight: page.data && page.data.fileRight ? page.data.fileRight : null,
         // filesUpload: page.data && page.data.filesUpload ? page.data.filesUpload.map( element => element) : null,
         fileSelectedAdh: page.data && page.data.fileSelectedAdh ? page.data.fileSelectedAdh : null,
         filesSelectedCover : page.data && page.data.filesSelectedCover ? page.data.filesSelectedCover : null,
         // fileRightSelected :  page.data && page.data.fileRightSelected ? page.data.fileRightSelected :  null,
         // fileConcession : page.data && page.data.fileConcession ? page.data.fileConcession : null,
         featuredArticle: featuredArt,
         customPage: page.data && page.data.customPage ? page.data.customPage : null,
         photo: page.data && page.data.photo ? page.data.photo : null,
         visuelDelta: page.data && page.data.visuelDelta ? page.data.visuelDelta : null,
         pageTypeConcession: page.data && page.data.pageTypeConcession ? page.data.pageTypeConcession : null,
         gabaritConcession: page.data && page.data.gabaritConcession ? page.data.gabaritConcession : null,
         visuelPageConcession: page.data && page.data.visuelPageConcession ? page.data.visuelPageConcession : null,
         offreCouverture : page.data && page.data.offreCouverture ? page.data.offreCouverture : null,
         pageComments : page.data && page.data.pageComments ? page.data.pageComments : null,
         sousTitresVignetes : page.data && page.data.sousTitresVignetes ? page.data.sousTitresVignetes : [],
      };
   }
   static fromLocal(page: LocalPage, slugifyService: SlugifyService): Page {
       return {
         id: page.id,
         catalog: page.catalog ? { '@id': page.catalog.id } : null,
         campaign: page.campagn ? { '@id': page.campagn.id } : null,
         elements: page.productsOnPage
            ? page.productsOnPage.map((productOnPage: ArticleOnPage) => ({
                 '@id': productOnPage.id,
              }))
            : null,
         files: page.files
            ? page.files.map((pageFile: PageFile) => ({
                 '@id': pageFile.id,
              }))
            : [],
         pageComments: [],
         data: {
            basket: page.basket
               ? page.basket.map(article => ({ '@id': article.id }))
               : null,
            title: page.title,
            titleCustom: page.titleCustom,
            noTitle: page.noTitle,
            customPage: page.customPage,
            type: page.type,
            section: page.section,
            order: page.order,
            tags: page.tags,
            draft: page.draft,
            version: page.version,
            commentCover: page.commentCover,
            commentInfoConcession: page.commentInfoConcession,
            preview: page.preview,
            titles: page.titles,
            logo: page.logo,
            photo: page.photo,
            visuelDelta: page.visuelDelta,
            // file: page.file,
            // fileRight: page.fileRight,
            // filesUpload: page.filesUpload,
            fileSelectedAdh: page.fileSelectedAdh,
            filesSelectedCover : page.filesSelectedCover,
            pageTypeConcession: page.pageTypeConcession,
            gabaritConcession: page.gabaritConcession,
            visuelPageConcession: page.visuelPageConcession,
            // fileRightSelected : page.fileRightSelected,
            // fileConcession: page.fileConcession,
            featuredArticle: page.featuredArticle ? { '@id': page.featuredArticle.id } : null,
            offreCouverture: page.offreCouverture,
            sousTitresVignetes: page.sousTitresVignetes
         },
      };
   }

  static toLocalOnlyFiles(page: Page): any {
    return {
      id: page.id,
      files: page.files
        ? page.files.map(element => element)
        : [],
    };
  }
}
