import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { translation } from '../i18n/fr_FR.js';
import {PageType} from '../refs/page-type';

@Injectable({
   providedIn: 'root',
})
export class TranslateService {
   data = translation;

   constructor(private http: HttpClient) {}

   getTypeDescription( type: string) {
     if ( type === PageType.COVER) {
       return 'Couverture';
     } else if ( type === PageType.THEMATIC) {
       return 'Thématique';
     } else if ( type === PageType.FREE) {
       return 'Libre';
     } else if ( type === PageType.MANDATORY) {
       return 'Obligatoire';
     } else if ( type === PageType.CONCESSION) {
       return 'Concession';
     } else if ( type === PageType.ADDRESSES) {
       return 'Adresses';
     } else {
       return 'Toutes';
     }
   }

   translate( text: string) {
     if (this.data[text] ) {
       return this.data[text];
     }
     return text;
   }
}
