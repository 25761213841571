import {CampagneType} from './campagne-type';

export class Format {
   id: number;
   campagneType: CampagneType;
   title: string;
   grammage: number;
   nbPages: number;
   sansCouv: boolean;
   pageAdresses: boolean;
   nbMandatory: number; // Number of mandatory pages => Free pages = nbPages - nbMandatory - 1
   // nbThematique: number; // Number of mandatory pages => Free pages = nbPages - nbMandatory - 1
   nbThematique: string; // Number of mandatory pages => Free pages = nbPages - nbMandatory - 1
   pageConcession: string;
   nbConcession: number;
   nbLibre: number; // Max number of personnalized pages
   isUsed: boolean; // Define if it is used in campagn
   checked?: boolean;   // description: string;
}


