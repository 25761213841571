<div class="format-form">
  <p class="warning">{{message}}</p>
   <mat-form-field class="form-field">
      <mat-label>{{ 'TITLE' | translate }}</mat-label>
      <input
         matInput
         [(ngModel)]="data.title"
         placeholder="{{ 'TITLE' | translate }}"
         required
      />
   </mat-form-field>
   <mat-form-field class="form-field">
      <mat-label>Grammage</mat-label>
      <input
         matInput
         [(ngModel)]="data.grammage"
         placeholder="Grammage"
         required
      />
   </mat-form-field>
   <mat-form-field class="form-field">
      <mat-label>{{ 'NB_PAGES' | translate }} {{ data.sansCouv ? '' : '(couverture comprise)' }}</mat-label>
      <input
         matInput
         [(ngModel)]="data.nbPages"
         placeholder="{{ 'NB_PAGES' | translate }}"
         type="number"
         required
      />
   </mat-form-field>
   <mat-form-field class="form-field">
      <mat-label>{{ 'NB_MANDATORY' | translate }}</mat-label>
      <input
         matInput
         [(ngModel)]="data.nbMandatory"
         placeholder="{{ 'NB_MANDATORY' | translate }}"
         type="number"
      />
   </mat-form-field>
  <div class="flex-form">
    <mat-label>{{ 'NB_THEMATIQUE' | translate }}</mat-label>
    <mat-radio-group
      [(ngModel)]="data.nbThematique"
    >
      <mat-radio-button class="example-radio-button" *ngFor="let choice of choices" [value]="choice">
        {{choice}}
      </mat-radio-button>
    </mat-radio-group>
  </div>
   <div class="flex-form">
         <mat-label>{{ 'PAGES_CONCESSION' | translate }}</mat-label>
         <mat-radio-group
                 [(ngModel)]="data.pageConcession"
         >
            <mat-radio-button class="example-radio-button" *ngFor="let choice of choices" [value]="choice">
               {{choice}}
            </mat-radio-button>
         </mat-radio-group>
   </div>
   <!--<mat-form-field *ngIf="data.pageConcession === 'Oui'" class="form-field">-->
   <!--&lt;!&ndash;<mat-form-field *ngIf="data.pageConcession" class="form-field">&ndash;&gt;-->
      <!--<mat-label>{{ 'NB_CONCESSION' | translate }}</mat-label>-->
      <!--<input-->
              <!--matInput-->
              <!--[(ngModel)]="data.nbConcession"-->
              <!--placeholder="{{ 'NB_CONCESSION' | translate }}"-->
              <!--type="number"-->
      <!--/>-->
   <!--</mat-form-field>-->
   <mat-form-field class="form-field" style="display: none">
      <mat-label>{{ 'NB_THEMATIQUE' | translate }}</mat-label>
      <input
              matInput
              [(ngModel)]="data.nbLibre"
              placeholder="{{ 'NB_THEMATIQUE' | translate }}"
              type="number"
      />
   </mat-form-field>
   <div class="resume-format">
     <div  *ngIf=" data.nbThematique === 'Non'  && data.pageConcession === 'Non' && data.nbPages && data.nbMandatory">
       <p >{{pagesRestantes()}}{{'HELP_FORMAT_DIALOG_FREE' |translate}}</p>
     </div>
     <div *ngIf=" data.nbThematique === 'Oui' && data.pageConcession === 'Non' && data.nbPages && data.nbMandatory">
       <p>{{pagesRestantes()}}{{'HELP_FORMAT_DIALOG_FREE_THEMATIQUE' |translate}}</p>
     </div>
     <div *ngIf="data.nbThematique === 'Non' && data.pageConcession === 'Oui' && data.nbPages && data.nbMandatory">
       <p>{{pagesRestantes()}}{{'HELP_FORMAT_DIALOG_FREE_CONCESSION' |translate}}</p>
     </div>
     <div *ngIf="data.nbThematique === 'Oui' && data.pageConcession === 'Oui' && data.nbPages && data.nbMandatory">
       <p>{{pagesRestantes()}}{{'HELP_FORMAT_DIALOG_FREE_THEMATIQUE_CONCESSION' |translate}}</p>
     </div>
   </div>
    <!--<div *ngIf="data.nbThematique && data.nbPages && data.nbMandatory">
        <p>{{'HELP_FORMAT_DIALOG' |translate}}{{data.nbPages - 1 - data?.nbThematique - data?.nbMandatory}}</p>
    </div>-->
   <!--<div *ngIf="data.nbConcession && data.nbThematique && data.nbPages && data.nbMandatory">-->
   <!--<mat-form-field class="form-field">-->
      <!--<mat-label>{{ 'FORMAT_DESCRIPTION' | translate }}</mat-label>-->
      <!--<input-->
              <!--matInput-->
              <!--[(ngModel)]="data.description"-->
              <!--placeholder="{{ 'FORMAT_DESCRIPTION' | translate }}"-->
      <!--/>-->
   <!--</mat-form-field>-->

   <!--[disabled]="data.nbPages < (data.nbThematique + data.nbMandatory + data?.nbConcession) - 1 ">-->
   <!-- [disabled]="(data.nbPages - 1  < data?.nbThematique + data?.nbMandatory + data?.nbConcession) || (data.nbPages - 1  < data?.nbThematique + data?.nbMandatory)"> -->
   <button mat-button (click)="onNoClick()" class="btn-grey">{{ 'CLOSE' | translate }}</button>
<!--   <button *ngIf="data.nbPages && data.nbMandatory && data.title && data.nbThematique && data.pageConcession" mat-button [mat-dialog-close]="data"  cdkFocusInitial-->
   <button
           *ngIf="data.nbPages && data.nbMandatory !== null && data.title && data.nbThematique && data.pageConcession"
           mat-button
           (click)="checkComposition(data)"
           cdkFocusInitial
           class="btn-green"
           [disabled]="!totalPagesOk()">
      {{ 'VALIDATE' | translate }}
   </button>
   <div *ngIf="!totalPagesOk()">
      <p>Le nombre de page obligatoire dépasse le nombre de page totales </p>
   </div>
  <div *ngIf="data.sansCouv || data.pageAdresses">
      <div><strong>{{ data.campagneType }}</strong></div>
      <div *ngIf="data.sansCouv">Ce format na pas de couverture</div>
      <div *ngIf="data.pageAdresses">Ce format a une page avec les adresses des adhérents)</div>
  </div>

</div>
