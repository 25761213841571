<div class="button-zone">
   <button (click)="updateTable()" mat-button>
      <mat-icon>cached</mat-icon>
      {{ 'RELOAD' | translate }}
   </button>
</div>
<mat-form-field>
   <input
      matInput
      (keyup)="applyFilter($event.target.value)"
      placeholder="{{ 'filter' | translate }}"
   />
</mat-form-field>
<p class="error-msg">{{message}}</p>
<mat-table [dataSource]="dataSource" matSort>
   <!-- Title Definition -->
   <ng-container cdkColumnDef="title">
      <mat-header-cell *cdkHeaderCellDef> Titre </mat-header-cell>
      <mat-cell *cdkCellDef="let row"> {{ row.label }} </mat-cell>
   </ng-container>

   <!-- Category Definition -->
   <ng-container cdkColumnDef="category">
      <mat-header-cell *cdkHeaderCellDef>
         {{ 'category' | translate }}
      </mat-header-cell>
      <mat-cell *cdkCellDef="let row">
         {{ row.category | translate }}
      </mat-cell>
   </ng-container>

   <ng-container cdkColumnDef="actions"
      ><mat-header-cell *cdkHeaderCellDef> Actions </mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <button
          mat-icon-button
          (click)="edit(row)"
        >
          <mat-icon>edit</mat-icon>
        </button>
         <button (click)="deleteEvent($event, row.id, row.title)" mat-button>
            <mat-icon>delete</mat-icon>
         </button>
      </mat-cell>
   </ng-container>

   <!-- Header and Row Declarations -->
   <mat-header-row
      *cdkHeaderRowDef="['title', 'category', 'actions']"
   ></mat-header-row>
   <mat-row
      *cdkRowDef="let row; columns: ['title', 'category', 'actions']"
   ></mat-row>
</mat-table>

<button (click)="addTitle()" mat-fab class="add-button">
   <mat-icon>add</mat-icon>
</button>
