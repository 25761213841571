<div>
   <mat-form-field>
      <input
         matInput
         (keyup)="applyFilter($event.target.value)"
         placeholder="{{ 'FILTER' | translate }}"
      />
   </mat-form-field>
</div>
<table mat-table [dataSource]="dataSource" class="full-width-table">
   <!-- Title Column -->
   <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef>Titre</th>
      <td mat-cell *matCellDef="let element">{{ element?.title }}</td>
   </ng-container>

   <!-- Nb Pages Column -->
   <ng-container matColumnDef="nbPages">
      <th mat-header-cell *matHeaderCellDef>Nb Pages</th>
      <td mat-cell *matCellDef="let element">{{ element?.nbPages }}</td>
   </ng-container>

   <!-- Nb Mandatory Column -->
   <ng-container matColumnDef="nbMandatory">
      <th mat-header-cell *matHeaderCellDef>Nb Obligatoires</th>
      <td mat-cell *matCellDef="let element"><span *ngIf="element?.nbMandatory !== 0">{{ element?.nbMandatory }}</span></td>
   </ng-container>

   <!-- formatType Column -->
   <ng-container matColumnDef="formatType">
      <th mat-header-cell *matHeaderCellDef>{{ 'FORMAT_TYPE' | translate }}</th>
      <td mat-cell *matCellDef="let element">
         {{ element?.type | translate }}
      </td>
   </ng-container>

   <!-- Description Column -->
   <ng-container matColumnDef="formatDescription">
      <th mat-header-cell *matHeaderCellDef>{{ 'FORMAT_DESCRIPTION' | translate }}</th>
      <td mat-cell *matCellDef="let element">
         {{ element?.description | translate }}
      </td>
   </ng-container>

   <!-- Actions Column -->
   <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element">
         <button
            mat-icon-button
            (click)="edit(element)"
            *ngIf="!element.isUsed"
         >
            <mat-icon>edit</mat-icon>
         </button>
         <button
            mat-icon-button
            (click)="delete(element)"
            *ngIf="!element.isUsed"
         >
            <mat-icon>delete</mat-icon>
         </button>
      </td>
   </ng-container>

   <tr
      mat-header-row
      *matHeaderRowDef="[
         'title',
         'nbPages',
         'nbMandatory',
         'formatType',
         'formatDescription',
         'actions'
      ]"
   ></tr>
   <tr
      mat-row
      *matRowDef="
         let row;
         columns: ['title', 'nbPages', 'nbMandatory', 'formatType','formatDescription', 'actions']
      "
   ></tr>
</table>

<div *ngIf="errorMessage" class="alert alert-danger">
   Error: {{ errorMessage }}
</div>

<button (click)="addFormat()" mat-fab class="add-button">
   <mat-icon>add</mat-icon>
</button>
