import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ArticleView } from '../article-list/article-list.component';
import {environment} from '../../../environments/environment';

@Component({
   selector: 'pm-df-article-details',
   templateUrl: './article-details.component.html',
   styleUrls: ['./article-details.component.css'],
})
export class ArticleDetailsComponent implements OnInit {
   articleValue: ArticleView;
   public urlBase = '';
   @Input() get article() {
      return this.articleValue;
   }
   set article(value: ArticleView) {
      this.articleValue = value;
      this.articleChange.emit(this.articleValue);
   }

   @Output() articleChange = new EventEmitter();

   @Output() favoriteStateChange = new EventEmitter();

   constructor() {}

   ngOnInit() {
     this.urlBase = environment.urlBase;
   }

   onArticleChange() {
      this.favoriteStateChange.emit(this.articleValue);
   }
}
