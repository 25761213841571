<button mat-icon-button class="close-button" [mat-dialog-close]="true" style="float: right">
  <mat-icon class="close-icon" color="default">close</mat-icon>
</button>
<div class="format-form">
    <mat-form-field class="form-field">
        <mat-label>{{ 'TITLE' | translate }}</mat-label>
        <input
                matInput
                [(ngModel)]="data.label"
                placeholder="{{ 'TITLE' | translate }}"
                required
        />
    </mat-form-field>
    <mat-form-field class="form-field">
        <mat-label>{{ 'TYPE_FORMATS' | translate }}</mat-label>
        <input
                matInput
                [(ngModel)]="data.typeFormat"
                placeholder="{{ 'TYPE_FORMATS' | translate }}"
                required
        />
    </mat-form-field>
        <button mat-button (click)="onNoClick()">{{ 'CLOSE' | translate }}</button>
    <button mat-button [mat-dialog-close]="data" cdkFocusInitial>
        {{ 'VALIDATE' | translate }}
    </button>
</div >
