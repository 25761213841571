import { Injectable } from '@angular/core';
import { Format } from '../refs/format';
import { ConfigTemplate } from './cat/config-template';

import { ConfigService } from './cat/config.service';

@Injectable({
   providedIn: 'root',
})
export class FormatsService extends ConfigTemplate<Format> {
   constructor(protected configService: ConfigService) {
      super(configService);
   }

   getType(): string {
      return 'format';
   }
}
