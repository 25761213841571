import { CatalogAction as LocalCatalogAction } from '../refs/catalog-action';
import {Catalog} from './catalog';
import {Ref} from './ref';

export class CatalogAction {
  id: number;
  catalog: Catalog| Ref = null;
  data: any = null;
  /*user: User;
  role: Role;
  statusPrev: CatalogStatus;
  statusActuel: CatalogStatus;
  commentaire: string;
  */

  static toLocal(catalogAction: CatalogAction): LocalCatalogAction {
    /*const catalog = catalogComment.catalog
       ? CatalogComment.toLocal(catalogComment.catalog as Catalog)
       : null;*/
    return {
      id: catalogAction.id,
      catalog: catalogAction.catalog ?
        Catalog.toLocal(catalogAction.catalog as Catalog)
        : null,
      type: catalogAction.data && catalogAction.data.type ? catalogAction.data.type : null,
      date: catalogAction.data && catalogAction.data.date ? catalogAction.data.date : null,
      user: catalogAction.data && catalogAction.data.user ? catalogAction.data.user : null,
      role: catalogAction.data && catalogAction.data.role ? catalogAction.data.role : null,
      commentaire: catalogAction.data && catalogAction.data.commentaire ? catalogAction.data.commentaire : null,
      statusPrev: catalogAction.data && catalogAction.data.statusPrev ? catalogAction.data.statusPrev : null,
      statusActuel: catalogAction.data && catalogAction.data.statusActuel ? catalogAction.data.statusActuel : null,
      getDescription(): string {
        if(this.type === 'action'){
          return 'Chgt. état';
        }else if(this.type === 'chg_etat_admin' ){
          return 'Chgt. état Admin';
        }else if(this.type === 'message' ){
          return 'Message';
        }else if(this.type === 'download_bat' ){
          return 'Téléchargement BAT';
        }else if(this.type === 'download_cdf' ){
          return 'Téléchargement CDF';
        }else if(this.type === 'download_encarteur' ){
          return 'Téléchargement fichier encartage';
        }else if(this.type === 'download_routeur' ){
          return 'Téléchargement fichier routage';
        }else if(this.type === 'upload_encateurFile' ){
          return 'Dépose fichier encartage';
        }else if(this.type === 'upload_routeurFile' ){
          return 'Dépose fichier routage';
        }else if(this.type === 'ficherSupprime_routeurFile' ){
          return 'Suppression de fichier routage';
        }else if(this.type === 'ficherSupprime_encateurFile' ){
          return 'Supression de fichier encartage';
        }else{
          return this.type;
        }
      }
    };
  }
  static fromLocal(catalogAction: LocalCatalogAction): CatalogAction {
    return {
      id: catalogAction.id,
      catalog: { '@id': catalogAction.catalog.id },
      data: {
        type: catalogAction.type,
        date: catalogAction.date,
        user: catalogAction.user,
        role: catalogAction.role,
        commentaire: catalogAction.commentaire,
        statusPrev: catalogAction.statusPrev,
        statusActuel: catalogAction.statusActuel,
      },
    };
  }
}
