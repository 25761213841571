import { Pipe, PipeTransform } from '@angular/core';

import { Company } from '../../refs/company';

@Pipe({ name: 'customersFilter', pure: false })
export class CustomersFilterPipe implements PipeTransform {
   transform(customers: Company[], value: string) {
      return customers.filter(val =>
         val && val.companyName
            ? val.companyName.toUpperCase().includes(value.toUpperCase())
            : 'not set'
      );
   }
}
