import { Injectable } from '@angular/core';

import { DFApiService } from './dfapi.service';
import { DFApiReloaderService } from './dfapi-reloader.service';
import { LoadingService } from './loading.service';

@Injectable({
   providedIn: 'root',
})
export class TagsService extends DFApiService {
   constructor(
      apiReloader: DFApiReloaderService,
      loadingService: LoadingService
   ) {
      super(apiReloader, loadingService);
      this.setLocation(['core', 'tag']);
   }

   map(tag: any) {
      return { ...tag };
   }
   getList(): Promise<any[]> {
      /**
       * Only tags on articles are used for catalogs
       */
      const query = {
         filter: {
            tagGroup: {
               name: { $in: ['article'] },
            },
            tagCategory: {
               name: {
                  $in: ['category_pukhl'],
                  // $in: ['category_bm81r'], // pour instance de test
               },
            },
         },
        field: ['name', 'title'],
        order: ['title', 'asc']
      };

      return new Promise((resolve, reject) => {
         this.list(query).then(res => {
            resolve(
               res.map(product => {
                  return this.map(product);
               })
            );
         });
      });
   }

  public async getTags(ids): Promise<any[]> {
      /**
       * Only tags on articles are used for catalogs
       */
      const query = {
         filter: {
            id: { $in: ids },
         },
         field: ['name', 'title'],
         order: ['title', 'asc']
      };

      return new Promise((resolve, reject) => {
         this.list(query).then(res => {
            resolve(
               res.map(product => {
                  return this.map(product);
               })
            );
         });
      });
   }
}
