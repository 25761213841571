<!--<h1 mat-dialog-title class="dialog-title">{{ 'VALIDATION' | translate }}</h1>-->
<div mat-dialog-content class="dialog-content">
  <span *ngIf="msg===''">{{ 'ARE_YOU_SURE' | translate }}</span>
  <span *ngIf="msg!==''">{{ msg | translate }}</span>

</div>
<div mat-dialog-actions class="dialog-actions">
   <button mat-button (click)="onNoClick()" class="dialog-action btn-white">
      {{ 'NO' | translate }}
   </button>
   <button
      mat-button
      [mat-dialog-close]="true"
      cdkFocusInitial
      class="dialog-action btn-green"
   >
      {{ 'YES' | translate }}
   </button>
</div>
