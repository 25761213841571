import { Injectable } from '@angular/core';

import { DFApiService } from '../dfapi.service';
import { DFApiReloaderService } from '../dfapi-reloader.service';
import { LoadingService } from '../loading.service';

import { PageElement } from '../../intermediate-refs/page-element';
import { ArticleOnPage } from '../../refs/article-on-page';
import { SlugifyService } from '../../shared/slugify.service';
import { FieldResolverService } from '../field-resolver.service';
import {LabelsService} from '../labels.service';

@Injectable({
   providedIn: 'root',
})
export class PageElementService extends DFApiService {
   constructor(
      apiReloader: DFApiReloaderService,
      loadingService: LoadingService,
      private slugifyService: SlugifyService,
      private fieldResolver: FieldResolverService,
      private labelService: LabelsService
   ) {
      super(apiReloader, loadingService);
      this.setLocation(['catalog', 'pageElement']);
   }

   public page$ = this.getList;

   getJoinableElements(): string[] {
      return [
         'article',
         'article.pictures',
         'page'];
   }
   /*public async resolve(pageElement: PageElement): Promise<ArticleOnPage> {
     console.log('resolve');
     console.log(pageElement);
     const result = PageElement.toLocal(pageElement) as ArticleOnPage;
     if (result.label) {
       const label = await this.fieldResolver.resolveOneField(
         result.label,
         this.labelService
       );
       result.label = label;
     }
     return result;
   }
*/
   public getList(extraQuery: any): Promise<ArticleOnPage[]> {
      // J'ai limité cette requete car j'ai pas trouvé a quoi elle sert.
      // Apparement elle sert à recuperer la liste d'articules par page
      // Du coup,j'ai laissé que les champs id et page.
      const query = {
         ...extraQuery,
         join: this.getJoinableElements(),
         fields: [
           'id',
           'page',
           'article'
         ]
      };
      return new Promise((resolve, reject) => {
         this.list(query).then(res => {
            resolve(res.map(val => PageElement.toLocal(val)));
         });
      });
   }

   public getById(id: number): Promise<ArticleOnPage> {
      const query = {
         filter: {
            id: {
               $in: [id],
            },
         },
         join: this.getJoinableElements(),
      };
      return new Promise((resolve, reject) => {
         this.list(query).then(res => {
            resolve(
               res.length === 0
                  ? null
                  : (PageElement.toLocal(res[0]) as ArticleOnPage)
            );
         });
      });
   }
 /* public async getList(): Promise<ArticleOnPage[]> {
    const query = {
      join: this.getJoinableElements(),
    };
    const res = await this.list(query);
    const result = await( Promise.all (
      res.map(async (pageElement) => await this.resolve(pageElement))
    ) as Promise<ArticleOnPage[]>);
    return  result;
  }

  public async getById(id: number): Promise<ArticleOnPage> {
    const query = {
      filter: {
        id: {
          $in: [id],
        },
      },
      join: this.getJoinableElements(),
    };
    const res = await this.list(query);
    if (res.length === 0) {
      return null;
    }
    return await this.resolve(res[0]);
  }
*/
   public create(obj: ArticleOnPage): Promise<ArticleOnPage> {
      const config = PageElement.fromLocal(obj, this.slugifyService);
      delete config.id;
      return new Promise((resolve, reject) => {
         this.put(config).then(res => {
            resolve(PageElement.toLocal(res) as ArticleOnPage);
         });
      });
   }

   public update(obj: ArticleOnPage): Promise<ArticleOnPage> {
      const config = PageElement.fromLocal(obj, this.slugifyService);
      delete config.id;
      return new Promise((resolve, reject) => {
         this.patch((obj as any).id, config).then(res => {
            resolve(PageElement.toLocal(res) as ArticleOnPage);
         });
      });
   }
}
