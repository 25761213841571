import { Injectable } from '@angular/core';

@Injectable({
   providedIn: 'root',
})
export class FieldResolverService {
   protected cache = {};

   async resolveField(tab, getFn, setFn, service) {
      // console.log(service.constructor.name);
      if(typeof service === 'object'){
        if( !this.cache.hasOwnProperty(service.constructor.name)){
          this.cache[service.constructor.name] = [];
        }
      }
      const initialIdTab = tab
         .map(val => (getFn(val).id ? getFn(val).id : getFn(val)['@id']))
         .filter(val => val);

      const cachedOutput = initialIdTab
       .map(id => {
         const initialVal = tab.find(
           v => getFn(v).id === id || getFn(v)['@id'] === id
         );
         const val = this.cache[service.constructor.name].find(t => t.id === id);
         return setFn(initialVal, val);
       })
       .filter(t => t); // avoid null values
      if(cachedOutput.length === initialIdTab.length){
        // console.log('!!!!!!!!!!!!!!!!!!! trouvé dans le cache!')
        return cachedOutput;
      }else{
        // console.log('LE CACHE EST TOUJOURS VIDE', this.cache[service.constructor.name]);
      }
      const query = {
         filter: {
            id: {
               $in: initialIdTab,
            },
         },
      };
      const serviceResult = await service.getList(query);
      const output = initialIdTab
         .map(id => {
            const initialVal = tab.find(
               v => getFn(v).id === id || getFn(v)['@id'] === id
            );
            const val = serviceResult.find(t => t.id === id);
            return setFn(initialVal, val);
         })
         .filter(t => t); // avoid null values
      for (const item of output){
        const val = this.cache[service.constructor.name].find(t => t.id === item.id);
        if(!val){
          this.cache[service.constructor.name].push(item);
        }
      }
      // console.log(this.cache[service.constructor.name]);
      return output;
   }

   async resolveOneField(object, service) {
      if (object !== null) {
        const id = object.id ? object.id : object['@id'];
        const query = {
          filter: {
            id: {
              $in: [id],
            },
          },
        };
        const serviceResult = await service.getList(query);
        return serviceResult.find(t => t.id === id);
      }

   }
}
