<div class="loading-overlay" *ngIf="loading">
   <span class="fa fa-spinner spinner"></span>
</div>

<div class="color max-width-100" data-version="1.0.1">
   <div class="float-left logo">
      <img
              mat-card-image
              src="assets/images/deltaForce-logo-fullGreen.svg"
              alt="Delta force"
              class="pointer-hover"/>
   </div>
   <div mat-dialog-title class="dialog-title float-left ec main-title">Bienvenue dans votre espace catalogue Delta Force <span *ngIf="env!='prod'"> - {{ environment.environmentName }} !</span></div>
   <div mat-dialog-title class="dialog-title float-right ec" *ngIf="userRole === 'Agency'"><a [href]="urlBase+'?app/article/part#'" target="_blank">Accès au CRM</a></div>
   <div class="cl"></div>
</div>

<nav class="navbar navbar-expand navbar-light bg-light">
   <ul class="navbar-nav">
      <li class="nav-item">
         <a
            class="nav-link"
            [routerLink]="['/catalogs']"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            >{{
               (userRole === 'Admin' ? 'DASHBOARD' : 'CATALOGS') | translate
            }}</a
         >
      </li>
      <li class="nav-item" *ngIf="userRole === 'Admin' || userRole === 'Agency'">
         <a
            class="nav-link"
            [routerLink]="['/campagns']"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            >{{ 'CAMPAGNS' | translate }}</a
         >
      </li>
      <li class="nav-item" *ngIf="userRole === 'Admin'">
         <a
                 class="nav-link"
                 [routerLink]="['/type-format']"
                 routerLinkActive="active"
                 [routerLinkActiveOptions]="{ exact: true }"
         >{{ 'TYPE_FORMATS' | translate }}</a
         >
      </li>
      <!--<li class="nav-item" *ngIf="userRole === 'Admin'">-->
         <!--<a-->
            <!--class="nav-link"-->
            <!--[routerLink]="['/formats']"-->
            <!--routerLinkActive="active"-->
            <!--[routerLinkActiveOptions]="{ exact: true }"-->
            <!--&gt;{{ 'FORMATS' | translate }}</a-->
         <!--&gt;-->
      <!--</li>-->

      <li class="nav-item" *ngIf="userRole === 'Admin'">
         <a
            class="nav-link"
            [routerLink]="['/titles']"
            routerLinkActive="active"
            >{{ 'TITLES' | translate }}</a
         >
      </li>
      <li class="nav-item" *ngIf="userRole === 'Admin'">
         <a
            class="nav-link"
            [routerLink]="['/labels']"
            routerLinkActive="active"
            >{{ 'Labels' | translate }}</a
         >
      </li>
      <li class="nav-item" *ngIf="userRole === 'Admin'">
         <a
            class="nav-link"
            [routerLink]="['/animateurs']"
            routerLinkActive="active"
            >{{ 'Animateurs' | translate }}</a
         >
      </li>
<!--     <li class="nav-item" *ngIf="userRole === 'Admin'">-->
<!--       <a-->
<!--         class="nav-link"-->
<!--         [routerLink]="['/gestion']"-->
<!--         routerLinkActive="active"-->
<!--         [routerLinkActiveOptions]="{ exact: true }"-->
<!--       >Gestion</a-->
<!--       >-->
<!--     </li>-->
   </ul>
   <ul class="navbar-nav ml-auto">
      <li class="nav-item" *ngIf="isLoggedIn">
         <a class="nav-link" matTooltip="{{ userMail }}"
            >{{ fullName }} ({{ userCompany }})</a
         >
      </li>
      <li class="nav-item" *ngIf="isLoggedIn">
         <!--<a class="nav-link">{{ userRole }}</a>-->
         <a class="nav-link">{{ userRole | translate }}</a>
      </li>
   </ul>
</nav>

<div class="container-fluid">
   <div class="row">
      <div
         class="col-md-12"
         [@slideInAnimation]="o.isActivated ? o.activatedRoute : ''"
      >
         <router-outlet #o="outlet"></router-outlet>
      </div>
   </div>
</div>
