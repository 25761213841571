import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { MatDateFormats } from '@angular/material/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { MatChipsModule } from '@angular/material/chips';

import { StarComponent } from './star.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { WaveDetailsComponent } from '../wave-details/wave-details.component';
import { MaterialModule } from '../material/material.module';

import { CustomersListComponent } from '../customers/customers-list/customers-list.component';
import { CustomerSimpleComponent } from '../customers/customer-simple/customer-simple.component';
import { CustomersFilterPipe } from '../customers/customers-list/customers-filter.pipe';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { CampagnTitlelistComponent } from '../titles/campagn-titlelist/campagn-titlelist.component';
import { TranslatePipe } from '../translate.pipe';
import { TranslateService } from '../services/translate.service';
import { CampagnFormatListComponent } from '../format/campagn-format-list/campagn-format-list.component';
import { FormatListComponent } from '../format/format-list/format-list.component';
import { HelpPanelComponent } from '../help-panel/help-panel.component';
import { ArticleSearchPipe } from '../article/article-search.pipe';
import { ArticleDetailsComponent } from '../article/article-details/article-details.component';
import { ArticleOnPageDetailsComponent } from '../article/article-on-page-details/article-on-page-details.component';
import { ArticleListComponent } from '../article/article-list/article-list.component';

import { CampagnPageListComponent } from '../campagn/campagn-pagination/campagn-page-list/campagn-page-list.component';
// tslint:disable-next-line:max-line-length
import { CampagnPageSimpleComponent } from '../campagn/campagn-pagination/campagn-page-list/campagn-page-simple/campagn-page-simple.component';
import { AdminPageComponent } from '../page-builder/admin-page/admin-page.component';
import { ListItemComponent } from './list-item/list-item.component';

import { CatalogListComponent } from '../catalog/catalog-list/catalog-list.component';
import { CatalogDetailsComponent } from '../catalog/catalog-details/catalog-details.component';

import { DashboardCatalogListComponent } from '../catalog/dashboard-catalog-list/dashboard-catalog-list.component';
import { CatalogSelectWaveComponent } from '../catalog/catalog-select-wave/catalog-select-wave.component';
import { CatalogSelectFormatComponent } from '../catalog/catalog-select-format/catalog-select-format.component';
import { WaitingProcessChatComponent } from '../catalog/waiting-process-chat/waiting-process-chat.component';
import { CatalogStepperComponent } from '../catalog/catalog-stepper/catalog-stepper.component';

import { CatalogPageReviewComponent } from '../catalog/catalog-page-review/catalog-page-review.component';
import { CatalogPageReturnComponent } from '../catalog/catalog-page-return/catalog-page-return.component';

import { NgxFileDropModule } from 'ngx-file-drop';
import { CampagnTypeFormatListComponent } from '../type-format/campagn-type-format-list/campagn-type-format-list.component';
import { RoutageComponent } from '../routage/routage.component';
import { EncarteurComponent } from '../encarteur/encarteur.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AdressCoverComponent } from '../catalog/adress-cover/adress-cover.component';
import { DateAdapter } from '@angular/material/core';
import {
   MomentDateAdapter,
   MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {ShowImageComponent} from '../catalog/catalog-page-return/show-image/show-image.component';
import {UploadPictureComponent} from './upload-picture/upload-picture.component';


export const MY_FORMAT: MatDateFormats = {
   parse: {
      dateInput: ['DD/MM/YYYY', 'DDMMYYYY', 'DD/MM/YY', 'DDMMYY'],
   },
   display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'DD/MM/YYYY',
      monthYearA11yLabel: 'MMMM YYYY',
   },
};

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxFileDropModule,
    MatChipsModule,
    NgxDaterangepickerMd.forRoot({
      separator: ' - ',
      applyLabel: 'Appliquer',
      clearLabel: 'Effacer',
      cancelLabel: 'Annuler',
      format: 'DD/MM/YYYY',
    }),
    PdfViewerModule
  ],
   declarations: [
      ArticleDetailsComponent,
      ArticleOnPageDetailsComponent,
      ArticleListComponent,
      ArticleSearchPipe,
      CampagnFormatListComponent,
      CampagnTypeFormatListComponent,
      CampagnPageListComponent,
      CampagnPageSimpleComponent,
      CampagnTitlelistComponent,
      CustomerSimpleComponent,
      CustomersFilterPipe,
      CustomersListComponent,
      FormatListComponent,
      HelpPanelComponent,
      StarComponent,
      TranslatePipe,
      WaveDetailsComponent,
      AdminPageComponent,
      ListItemComponent,
      CatalogListComponent,
      CatalogDetailsComponent,
      DashboardCatalogListComponent,
      CatalogSelectWaveComponent,
      CatalogSelectFormatComponent,
      WaitingProcessChatComponent,
      CatalogStepperComponent,
      CatalogPageReviewComponent,
      CatalogPageReturnComponent,
      RoutageComponent,
      DashboardComponent,
      EncarteurComponent,
      AdressCoverComponent,
      ShowImageComponent,
      UploadPictureComponent
   ],
   exports: [
      ArticleDetailsComponent,
      ArticleOnPageDetailsComponent,
      ArticleListComponent,
      CampagnFormatListComponent,
      CampagnTypeFormatListComponent,
      CampagnPageListComponent,
      CampagnPageSimpleComponent,
      CampagnTitlelistComponent,
      CommonModule,
      CustomerSimpleComponent,
      CustomersListComponent,
      FormatListComponent,
      RoutageComponent,
      FormsModule,
      HelpPanelComponent,
      MaterialModule,
      NgxDaterangepickerMd,
      StarComponent,
      TranslatePipe,
      WaveDetailsComponent,
      AdminPageComponent,
      ListItemComponent,
      CatalogListComponent,
      CatalogDetailsComponent,
      DashboardCatalogListComponent,
      CatalogSelectWaveComponent,
      CatalogSelectFormatComponent,
      WaitingProcessChatComponent,
      CatalogStepperComponent,
      CatalogPageReviewComponent,
      CatalogPageReturnComponent,
      DashboardComponent,
      EncarteurComponent,
      AdressCoverComponent,
      ShowImageComponent,
      UploadPictureComponent
   ],
   providers: [
      CustomersFilterPipe,
      ArticleSearchPipe,
      TranslatePipe,
      TranslateService,
      { provide: MAT_DATE_LOCALE, useValue: 'fr' },
      {
         provide: DateAdapter,
         useClass: MomentDateAdapter,
         deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
      },
      { provide: MAT_DATE_FORMATS, useValue: MY_FORMAT },
   ],
   schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
