import { Component, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import {
   Router,
   Event,
   NavigationStart,
   NavigationEnd,
   NavigationError,
   NavigationCancel,
} from '@angular/router';

import { AuthService } from './user/auth.service';
import { slideInAnimation } from './app.animation';
import { TranslateService } from './services/translate.service';
import { LoadingService } from './services/loading.service';
import {environment} from '../environments/environment';
import {AnimateurService} from './services/animateur.service';

@Component({
   selector: 'pm-root',
   templateUrl: './app.component.html',
   styleUrls: ['./app.component.css'],
   animations: [slideInAnimation],
})
export class AppComponent implements AfterViewChecked {
   public urlBase = '';
   public env = '';
   public readonly environment = environment;

   get loading() {
      return this.loadingService.loading;
   }

   get isLoggedIn(): boolean {
      return this.authService.isLoggedIn;
   }

   get userName(): string {
      if (this.authService.currentUser) {
         return this.authService.currentUser.username;
      }
      return '';
   }

   get fullName(): string {
      if (this.authService.currentUser) {
         return this.authService.currentUser.fullname;
      }
      return '';
   }

   get userMail(): string {
      if (this.authService.currentUser) {
         return this.authService.currentUser.mail;
      }
      return '';
   }

   get userRole(): string {
      if (this.authService.currentUser) {
         return this.authService.currentUser.role;
      }
      return '';
   }

   get userCompany(): string {
      if (this.authService.currentUser) {
         return this.authService.currentUser.company.companyName;
      }
      return '';
   }

   constructor(
      private authService: AuthService,
      private router: Router,
      private translate: TranslateService,
      private loadingService: LoadingService,
      private cdRef: ChangeDetectorRef,
      private animateursSvc: AnimateurService
   ) {
      router.events.subscribe((routerEvent: Event) => {
          this.checkRouterEvent(routerEvent);
          if (routerEvent instanceof NavigationEnd) {
            if(this.inIframe()){
              try {
                const data = {
                  location : routerEvent.url
                };
                window.parent.postMessage(JSON.stringify(data), '*');
              }catch (e){
                console.log(e);
              }
            }else{
            }
          }
      });

     this.urlBase = environment.urlBase;
      if(environment.urlBase === 'https://app.blgcloud.com/deltaforce/'){
        this.env = 'prod';
      }else{
        this.env = 'dev';
      }

   }

   inIframe() {
     try {
       return window.self !== window.top;
     } catch (e) {
       return true;
     }
   }

   ngAfterViewChecked(): void {
      const loading = this.loadingService.loading;
      if (loading !== this.loading) {
         this.cdRef.detectChanges();
      }
   }

   checkRouterEvent(routerEvent: Event): void {
      if (routerEvent instanceof NavigationStart) {
         this.loadingService.loading = true;
      }

      if (
         routerEvent instanceof NavigationEnd ||
         routerEvent instanceof NavigationCancel ||
         routerEvent instanceof NavigationError
      ) {
         this.loadingService.loading = false;
      }
   }

   logOut(): void {
      this.authService.logout();
      this.router.navigateByUrl('/dashboard');
   }
}
