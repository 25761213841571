import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '../user/auth.service';
import {
   NgxFileDropEntry,
   FileSystemFileEntry,
} from 'ngx-file-drop';
import {Label} from '../refs/label';

export interface DialogData {
   label: string;
   picture: string;
   edit: boolean;
   element: Label;
   mtype: string;
}
@Component({
   selector: 'pm-df-label',
   templateUrl: './label.component.html',
   styleUrls: ['./label.component.css'],
})
export class LabelComponent {
   public files: NgxFileDropEntry[] = [];

   get userRole() {
      if (this.authService.currentUser) {
         return this.authService.currentUser.role;
      }
      return '';
   }

   constructor(
      private authService: AuthService,
      public dialogRef: MatDialogRef<LabelComponent>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData
   ) {}

   onNoClick(): void {
      this.dialogRef.close();
   }
   public dropped(files: NgxFileDropEntry[]) {
      if (files.length !== 1) {
         return;
      }
      const fileEntry = files[0].fileEntry as FileSystemFileEntry;
      if (!fileEntry.isFile) {
         // console.log(fileEntry);
         return;
      }
      fileEntry.file((file: File) => {
         const reader = new FileReader();
         reader.readAsDataURL(file);
         reader.onload = response => {
            this.data.picture = reader.result as string;
         };
      });
   }
}
