import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {TypeFormat} from '../../refs/type-format';

@Component({
  selector: 'pm-df-type-format-dialog',
  templateUrl: './type-format-dialog.component.html',
  styleUrls: ['./type-format-dialog.component.css']
})
export class TypeFormatDialogComponent implements OnInit {

  constructor(
      public dialogRef: MatDialogRef<TypeFormatDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: TypeFormat
  ) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
