<button mat-icon-button class="close-button" [mat-dialog-close]="true" style="float: right">
  <mat-icon class="close-icon" color="default">close</mat-icon>
</button>
<h3 mat-dialog-title class="dialog-title" *ngIf="!data.edit">Edition du Picto</h3>
<div mat-dialog-content class="dialog">
   <mat-form-field class="full-width-field">
      <input *ngIf="data.edit" matInput [(ngModel)]="data.label" placeholder="Titre" />
      <input *ngIf="!data.edit" matInput [(ngModel)]="data.element.title" placeholder="Titre" />
   </mat-form-field>
  <div class="blockImage" *ngIf="data.picture">
  <pdf-viewer
    *ngIf="data.mtype === 'pdf'"
    [src]="data.element.picture "
    [render-text]="false"
    [original-size]="true"
    [show-all]="false"
    [fit-to-page]="true"
    style="display: block; height: 100px;"
  >
  </pdf-viewer>
  <img-wrapper *ngIf="data?.mtype === 'jpg'  || data?.mtype === 'jpeg' || data?.mtype === 'png'">
    <img
      *ngIf="data?.mtype === 'jpg' || data?.mtype === 'jpeg' ||data?.mtype === 'png'"
      class="imgTaille"
      src="{{ data?.element.picture }}"
      alt="{{ data?.element.title }}"
    />
  </img-wrapper>
</div>
   <div class="drop-area" >
      <ngx-file-drop
         class="drop-zone"
         dropZoneLabel="Drop files here"
         (onFileDrop)="dropped($event)"
      >
         <ng-template
            ngx-file-drop-content-tmp
            directory="false"
            accept="png;jpg"
            let-openFileSelector="openFileSelector"
            class="drop-content"
         >
            <div class="drop-text" (click)="openFileSelector()">
               Déposez votre image ici ou cliquez pour parcourir.
            </div>
         </ng-template>
      </ngx-file-drop>
   </div>
  <br>
   <img src="{{ data.picture }}" class="image-zone" *ngIf="data.picture" />
   <div mat-dialog-actions>
      <button mat-button (click)="onNoClick()">{{ 'CLOSE' | translate }}</button>
      <button mat-button [mat-dialog-close]="data" cdkFocusInitial>{{ 'VALIDATE' | translate }}</button>
   </div>
</div>
