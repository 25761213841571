export const translation = {
   ADD_FORMAT: 'Ajouter Composition',
   ADD_PAGE_TO_CAMPAGN: "Ajouter une page à l'opération catalogue",
   Autumn: 'Automne',
   CAMPAGN: 'Opération',
   CAMPAGNS: 'Opérations catalogue',
   CAMPAGN_TITLE: 'Titre de la campagne',
   CANCEL: 'Annuler',
   CATALOG_TITLE: 'Titres référencés',
   CATALOG_CAMPAGN: 'Titres sélectionnés',
   CHAT: 'Chat',
   CLOSE: 'Fermer',
   COMMERCIAL_CONDITIONS: 'Ecrire un commentaire',
   COVER: 'Couverture',
   CUSTOMERS: 'Adhérents',
   User: 'utilisateur',
   Catalog: 'Catalogue',
   CATALOGS: 'Catalogues',
   CATALOGS_HISTORY: 'Historique de catalogues',
   category: 'Catégorie',
   DASHBOARD: 'Tableau de bord',
   DASHBOARD_CAMPAGNS: 'Gestion des opérations catalogue',
   DASHBOARD_CAMPAGNS_TEXT: 'Gestion des opérations catalogue',
   DASHBOARD_CAMPAGN_CREATION: 'Créer une opération catalogue',
   DASHBOARD_CAMPAGN_CREATION_TEXT: 'Créer une opération catalogue',
   DASHBOARD_FORMATS: 'Gestion des compositions',
   DASHBOARD_FORMATS_TEXT: 'Gestion des compositions',
   Draft: 'Valider le brouillon',
   DEADLINE_PAGE_LAYOUT: 'Deadline page layout',
   PAGE_LAYOUT_VALIDATION: 'Validation du chemin de fer',
   Equipment: 'Équipement',
   FILTER: 'Rechercher un produit',
   FILTER_CATALOGUE: 'Rechercher',
   FORMATS: 'Compositions',
   TYPE_FORMATS: 'Type de Formats',
   FORMAT_DESCRIPTION: 'Commentaire',
   FORMATS_AND_PAGES: 'Formats & pages',
   FORMAT_TYPE: 'Type de format',
   FREE: ' Pages libres ',
   GENERAL: 'Général',
   ADRESS_COUVERTURE: 'Adresses Couverture',
   MANDATORY: 'Pages obligatoires',
   THEMATIQUE: 'Pages thématiques',
   CONCESSION: 'Pages concessions',
   ETOUCONCESSION: ' Pages libres et/ou Pages concessions',
   ETOUTHEMATIQUE: ' Pages thématiques et/ou Pages libres',
   ETOUTHEMATIQUE_CONCESSION:
      ' Pages thématiques et/ou Pages libres et/ou Pages concessions',
   MAX_NB_PERSO: 'Nombres de pages libres max',
   NB_MANDATORY: 'Nombre de pages obligatoires',
   // NB_THEMATIQUE: 'Nombre de pages thématiques',
   NB_THEMATIQUE: 'Autoriser les pages thématiques',
   NB_CONCESSION: 'Nombre de pages concession',
   NB_PAGES: 'Nombre total de pages',
   PAGES_CONCESSION: 'Autoriser les pages Concession',
   ROUTING: 'Souhaitez-vous faire un routage ?',
   ROUTING_2: 'Routage',
   ENCARTAGE: 'Souhaitez-vous faire un encartage ?',
   ENCARTAGE_2: 'Encartage',
   HELP_FORMAT_DIALOG_FREE: ' Pages disponibles pour les pages libres',
   HELP_FORMAT_DIALOG_FREE_CONCESSION:
      'Pages disponibles pour les pages libres et concessions',
   HELP_FORMAT_DIALOG_FREE_THEMATIQUE:
      ' Pages disponibles pour les pages libres et thématiques',
   HELP_FORMAT_DIALOG_FREE_THEMATIQUE_CONCESSION:
      ' Pages disponibles pour les pages libres, thématiques et concessions',
   NEXT: 'Suivant',
   PAGES: 'Pages',
   PUSH_ALL: 'Ajouter tout',
   PAGE_LAYOUT: 'Création du chemin de fer',
   EXCHANGE_WITH_AGENCY: "Échanges avec l'agence",
   BAT: 'BAT POUR IMPRESSION',
   PRINTING_ROUTING: 'Impression',
   ROUTING_DATE: 'Routage',
   ROUTING_NUMBER: 'Quantité routage',
   POSTE_DATE: 'Poste',
   POSTE_DATE_DEPOT: 'Date de Dépôt Poste',
   DIFFUSION_DATE: 'Date de Diffusion',
   PERIODE_DIFFUSION: 'Période de Diffusion',
   START_DIFFUSION: 'Début de diffusion',
   END_DIFFUSION: 'Fin de Diffusion',
   PERIODE_START_DIFFUSION_ADMIN: 'Période de diffusion',
   PERIODE_START_DIFFUSION_ADH: 'Période de diffusion',
   Specialist: 'Spécialiste',
   SEARCH: 'Recherche',
   Spring: 'Printemps',
   Summer: 'Été',
   TITLE: 'Titre',
   TITLE_FREE: 'Titre personnalisé',
   LABEL: 'Nom',
   NOM: 'Nom',
   TITLES: 'Titres',
   Tabloid: 'Tabloïd',
   VALIDATE: 'Valider',
   VALIDATE_CAMPAGNE: 'Valider mon chemin de fer',
   VALIDATE_CATALOG: 'Valider le catalogue complet',
   VALIDATE_ECHANGE: 'Valider le catalogue',
   RELECTURE_DF: 'Envoyer BAT à la relecture DF',
   ENVOYE_USER: "Envoyer la modification à l'adherent",
   ENVOYE_DF: "Envoyer la modification à l'agence",
   VALIDATION: 'Validation',
   ARE_YOU_SURE: 'Êtes-vous sûr(e) ?',
   ARE_YOU_SURE_TITLE: 'Êtes-vous sûr(e) de vouloir supprimer ce titre ?',
   ARE_YOU_SURE_FORMAT: 'Êtes-vous sûr(e) de vouloir supprimer ce format ?',
   ARE_YOU_SURE_FORMAT_TYPE: 'Êtes-vous sûr(e) de vouloir supprimer ce type de format ?',
   ARE_YOU_SURE_LABEL: 'Êtes-vous sûr(e) de vouloir supprimer ce picto ?',
   ARE_YOU_SURE_CAMPAIGN: 'Êtes-vous sûr(e) de vouloir supprimer cette opération catalogue ?',
   ARE_YOU_SURE_CATALOGUE: 'Êtes-vous sûr(e) de vouloir supprimer le catalogue de cet adhérent ?',
   ARE_YOU_SURE_PAGE: 'Êtes-vous sûr(e) de vouloir supprimer cette page ?',
   ARE_YOU_SURE_ROUTEUR: 'Êtes-vous sûr(e) de vouloir supprimer ce routeur ?',
   ARE_YOU_SURE_ENCARTEUR: 'Êtes-vous sûr(e) de vouloir supprimer cet encarteur ?',
   Cloture_catalog:
      'Vous vous apprêtez à valider votre CHEMIN DE FER. Une fois validé, il ne sera plus possible de le modifier',
   NO: 'Non',
   YES: 'Oui',
   Valid: 'Mettre en brouillon',
   Winter: 'Hiver',
   STATE_SAVING: 'Sauvegarde en cours...',
   STATE_SAVED: 'Opération sauvegardée',
   Status: 'Statut',
   Actions: 'Actions',

   STATUS_created: 'Catalogue Ouvert',
   STATUS_draft: 'CDF en cours de préparation',
   STATUS_draftValide: 'CDF validé',
   STATUS_waitingProcessFromAgency: 'BAT 0 en cours de création',
   STATUS_waitingProcessFromAgencyDF: 'BAT 0 en relecture chez DF',
   STATUS_waitingProcessEditBAT: 'BAT en cours de modification',
   STATUS_waitingProcessFromUser: 'BAT en relecture chez l\'adhérent',
   STATUS_validated: 'BAT Validé',
   STATUS_operationValidated: 'Opération Validée',
   STATUS_printing: 'Impression en cours',
   STATUS_routing: 'Routage en cours',
   STATUS_delivery: 'En cours de livraison',
   STATUS_filesSent: 'Fichiers HD livrés à l’imprimeur',

   RELOAD: 'Actualiser',
   back: 'Précédent',
   next: 'Suivant',
   next_step: 'Étape Suivante',
   previous_step: 'Étape Précédente',
   reset: 'Remise à zéro',

   company: 'Entreprise',
   routingDate: 'Date de routage',
   diffusionDate: 'Date de diffusion',
   waveSelected: 'Vague',

   imposed: 'Imposé',
   title: 'Titre',
   filter: 'Filtrer',
   All: 'Tous',
   Mandatory: 'Obligatoire',
   Thematic: 'Thématique',
   Cover: 'Couverture',
   Free: 'Libre',
   Concession: 'Concession',
   TODO: 'A créer',
   Addresses: 'Adresses',

   retailer: 'Détaillant',
   servicePoint: 'Point de service',

   GENERAL_HELP_SHORT: 'Paramètres généraux',
   GENERAL_HELP:
      "Indiquez pour chaque Opération Catalogue son titre principal, les dates butoirs, les contacts disponibles ainsi que le tag correspondant aux articles de l'Opération (provenant du backoffice)",
   FORMATS_HELP_SHORT: 'Sélection des Compositions',
   FORMATS_HELP:
      'Cochez les compositions disponibles pour cette Opération Catalogue. Il manque une composition ? Ajoutez la nouvelle composition  via le bouton + en bas de la page',
   // ' via le menu principal "Compositions" dans l\'en-tête et revenez à cette étape.',
   TYPE_FORMATS_HELP_SHORT: 'Sélection du type de format',
   TYPE_FORMATS_HELP:
      'Cochez le type de format disponible pour cette Opération Catalogue. Il manque un type de format ? Ajoutez le nouveau type de format via le menu principal "Type de formats" dans l\'en-tête et revenez à cette étape.',
   PAGES_HELP_SHORT: 'How to : pages',
   PAGES_HELP:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
   CUSTOMERS_HELP_SHORT: 'Sélection des Adhérents',
   CUSTOMERS_HELP:
      'Sélectionnez la liste des Adhérents qui devront accéder à cette Opération Catalogue. Cochez tous les adhérents ou un à un selon les autorisations souhaitées.',
   TITLES_HELP_SHORT: 'Sélection des Titres',
   TITLES_HELP:
      'Sélectionnez un à un les titres des pages mis à disposition des adhérents ou par lot en les cochant puis en cliquant sur la flèche bleue.',
   FINALISATION: 'Finalisation',
   FINALISATION_HELP_SHORT: 'Finalisation du paramétrage',
   FINALISATION_HELP:
      "Validez ou annuler la création de l'Opération Catalogue. L'enregistrement est automatique, mais vous pouvez dès cette étape réinitialiser tous les paramètres définis.",
   LABELS: 'Pictos',
   Labels: 'Pictos',
   Member: 'Adhérent',
   Agency: 'Agence',
   Animator: 'Animateur',
   Admin: 'Administrateur',
   Routing: 'Routeur',

   Upload: 'Déposer un fichier',
   Delete: 'Supprimer',
   RETROPLANNING: 'Rétroplanning',
   ADRESSE: 'Adresse',
   ADDRESS: 'Adresse',
   CP: 'Code postal',
   DATE_LIVAISON: 'Date de livraison',
   QUANTITY: 'Quantité',
   COUNTRY: 'Pays',
   CITY: 'Ville',
   COMPLEMENT: 'Complément',
   CONTACT: 'Contact',
   ADRESSE_LIVAISON: 'Adresse de livraison',
   DELIVERY: 'Livraison',
   AMBIANCE: "Photos d'ambiance",
   DROITE: 'Partie de droite',
   PERSONNALISER_PAGE: 'Personnaliser la mise en page',
   USE_DELTA: 'Utiliser la mise en page de delta Force',
   Preview: 'Prévisualisation de la page (cliquez pour zoomer)',
   titlePage: 'Le titre de la page est :',
   COUVERTURE_COMPAGNY:
      'Sélectionnez la ou les entreprises à afficher sur la couverture',
   MessageChangementWave:
      'Si les dates de vos vagues ne sont pas respectées, vous serez automatiquement basculé dans la vague suivante',
  MessageASaisir: 'Saisissez votre message ici',
  TitlePictoDialog: 'Pictos à ajouter',
  TitleImageDialog: 'Images disponibles',
  CreateCatalogLoaded: 'Recherche des nouveaux catalogues disponibles',
  GetCampagnLoaded: 'Chargement des opérations catalogues en cours',
  CATALOGS_NUMBER : 'Quantité catalogues',
  CATALOGS_NUMBER_TO_PRINT : 'Quantité totale de catalogues à imprimer ',
  NET_PRICE : 'Prix HT',
  DATE_ROUTAGE: 'Date de routage',
  link: 'Informations',
  NotFound: 'Aucun élément présent',
  Download: 'Télécharger le dossier de livraison',
  DownloadPDF: 'Télécharger le chemin de fer en format PDF',
  DownloadPDFCampagn: 'Télécharger PDF',
  TOUT_COCHER: 'Tout cocher/décocher',
  lastAction: 'Dernière Action'
};
