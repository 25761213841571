import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PageNotFoundComponent } from './page-not-found.component';
import { AuthGuard } from './user/auth.guard';
import { SelectiveStrategy } from './selective-strategy.service';
import { TitlesComponent } from './titles/titles.component';
import { TitleComponent } from './title/title.component';
import { FormatListComponent } from './format/format-list/format-list.component';

import { Role } from './refs/role';
import { LabelsComponent } from './labels/labels.component';
import {TypeFormatComponent} from './type-format/type-format.component';
import {AnimateursComponent} from './animateurs/animateurs.component';

@NgModule({
   imports: [
      RouterModule.forRoot(
         [
            {
               path: 'campagns',
               loadChildren: () =>
                 // @ts-ignore
                 // le @ts-ignore ignore les warning
                  import('./campagn/campagn.module').then(m => m.CampagnModule),
               canActivate: [AuthGuard],
               data: {
                  preload: false,
                  expectedRole: Role.ROLE_AGENCY,
               },
            },
            {
               path: 'formats',
               component: FormatListComponent,
               canActivate: [AuthGuard],
               data: {
                  preload: false,
                  expectedRole: Role.ROLE_ADMIN,
               },
            },
            {
                 path: 'titles',
                 component: TitlesComponent,
                 canActivate: [AuthGuard],
                 data: {
                     preload: false,
                     expectedRole: Role.ROLE_ADMIN,
                 },
            },
            {
               path: 'type-format',
               component: TypeFormatComponent,
               canActivate: [AuthGuard],
               data: {
                  preload: false,
                  expectedRole: Role.ROLE_ADMIN,
               },
            },
            {
               path: 'title',
               component: TitleComponent,
               canActivate: [AuthGuard],
               data: {
                  preload: false,
                  expectedRole: Role.ROLE_ADMIN,
               },
            },
            {
               path: 'labels',
               component: LabelsComponent,
               canActivate: [AuthGuard],
               data: {
                  preload: false,
                  expectedRole: Role.ROLE_ADMIN,
               },
            },
            {
               path: 'animateurs',
               component: AnimateursComponent,
               canActivate: [AuthGuard],
               data: {
                  preload: false,
                  expectedRole: Role.ROLE_ADMIN,
               },
            },
           // {
           //   path: 'gestion',
           //   component: GestionComponent,
           //   canActivate: [AuthGuard],
           //   data: {
           //     preload: false,
           //     expectedRole: Role.ROLE_ADMIN,
           //   },
           // },
            {
               path: 'catalogs',
               loadChildren: () =>
                 // @ts-ignore
                 // le @ts-ignore ignore les warning
                  import('./catalog/catalog.module').then(m => m.CatalogModule),
               canActivate: [AuthGuard],
               data: {
                  preload: false,
                  expectedRole: null,
               },
            },
            { path: '', redirectTo: 'catalogs', pathMatch: 'full' },
            { path: '**', component: PageNotFoundComponent },
         ],
         // { enableTracing: true, preloadingStrategy: SelectiveStrategy }
         // { enableTracing: true, preloadingStrategy: SelectiveStrategy }
{ preloadingStrategy: SelectiveStrategy, relativeLinkResolution: 'legacy' }
      ),
   ],
   exports: [RouterModule],
})
export class AppRoutingModule {}
