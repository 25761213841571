import { Component, OnInit, ViewChild } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TitleComponent } from '../title/title.component';
import { TitlesService } from '../services/titles.service';
import { Title } from '../refs/title';
import { TitleCategory } from '../refs/title-category';
import { MatListOption } from '@angular/material/list';
import { MatPaginator } from '@angular/material/paginator';
import {ValidationDialogComponent} from '../validation-dialog/validation-dialog.component';
import {SlugifyService} from '../shared/slugify.service';

@Component({
   selector: 'pm-df-titles',
   templateUrl: './titles.component.html',
   styleUrls: ['./titles.component.css', '../shared/global.css'],
})
export class TitlesComponent implements OnInit {
   public dataSource: MatTableDataSource<Title>;
   @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
   @ViewChild(MatSort, { static: true }) sort: MatSort;

   title: string;
   imposed: boolean;
   message = '';

   constructor(
     private titleService: TitlesService,
     public dialog: MatDialog,
     public strugiflyService: SlugifyService) {}

   ngOnInit() {
      this.updateTable();
   }

   updateTable() {
      this.titleService.getList().then(data => {
         data.sort((a, b) => a.label > b.label && 1 || -1);
         this.dataSource = new MatTableDataSource<Title>(data);
      });
   }

   addTitle() {
      const dialogRef = this.dialog.open(TitleComponent, {
         width: '40vw',
         data: { title: '', imposed: false, category: null, edit: true },
      });
      dialogRef.afterClosed().subscribe(result => {
         this.titleService.getList().then((titles => {
           const existTitle = titles.filter(title => title.label.trim() === result.title.trim());
           if (existTitle.length > 0) {
             this.message = 'Ce titre ne peut pas être créé car il existe déjà';
           } else {
             if (result && result.title !== '') {
               this.titleService
                 .create({
                   id: null,
                   label: result.title as string,
                   category: result.category as TitleCategory,
                 })
                 .then(() => {
                   this.updateTable();
                 });
             }
           }
         }));

      });
   }

   deleteEvent($event, rowId, name) {
      const dialogRef = this.dialog.open(ValidationDialogComponent, {
         width: '40vw',
         data: {
           rowId : rowId,
           msg : 'ARE_YOU_SURE_TITLE'
         },
      });
      dialogRef.afterClosed().subscribe(result => {
         if (result) {
            this.titleService.delete(rowId).then(() => {
               this.updateTable();
            });
         }
      });
   }

  // edit(element: Title) {
  //   const dialogRef = this.dialog.open(TitleComponent, {
  //     width: '40vw',
  //     data: { title: element.label, imposed: false, category: element.category },
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       console.log(result.element);
  //       this.titleService.update(result).then(() => {
  //       //   this.updateTable();
  //       });
  //     }
  //   });
  // }
  edit(element) {
    const dialogRef = this.dialog.open(TitleComponent, {
      width: '60vw',
      data: { title: element.label, imposed: false, category: element.category, element: element, edit: true},
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        result.element.category = result.category;
        result.element.label = result.title;
        this.titleService.update(result.element).then(() => {
          this.updateTable();
        });
      }
    });
  }

  applyFilter(filterValue: string) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
         this.dataSource.paginator.firstPage();
      }
   }

   onGroupsChange(options: MatListOption[]) {
      // map these MatListOptions to their values
      console.log(options.map(o => o.value));
   }
}
