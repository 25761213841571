import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {Format} from '../../refs/format';
import {TypeFormatService} from '../../services/type-format.service';
import {TypeFormat} from '../../refs/type-format';
import {FormatsService} from '../../services/formats.service';

@Component({
   selector: 'pm-df-format-dialog',
   templateUrl: './format-dialog.component.html',
   styleUrls: ['./format-dialog.component.css'],
})
export class FormatDialogComponent implements OnInit {
   private formatTypeList: TypeFormat[];
   choices: string[] = ['Oui', 'Non'];
   listCompo: Format[] = [];
   message = '';
   constructor(
      public dialogRef: MatDialogRef<FormatDialogComponent>,
      private typeFormatService: TypeFormatService,
      private formatsService: FormatsService,
      @Inject(MAT_DIALOG_DATA) public data: Format
   ) {
     this.formatsService.getList().then(formats => {
       this.listCompo = formats;
     });
   }

   ngOnInit() {
      // this.typeFormatService.getList().then( typeformat => {
      //    this.formatTypeList = typeformat;
      // });
   }

   getFormatTypeList() {
      return this.formatTypeList;
   }

   onNoClick(): void {
      this.dialogRef.close();
   }
  checkComposition(data) {
     this.message = '';
     if ( data.title === '' ) {
       this.message = 'Veillez saisir un titre';
     }
     if ( ! data.nbPages ) {
       if (this.message !== ''){
         this.message = this.message + '\n';
       }
       this.message = this.message + 'Veillez saisir le nombre des pages';
     }
     /*
     const titleExist = this.listCompo.filter(format => (format.title === data.title && format.id !== data.id));
     if (titleExist.length > 0) {
       if (this.message !== '') {
         this.message = this.message + '\n';
       }
       this.message = this.message + 'Ce titre de composition existe déjà';
     }
     */
     if(this.message === '') {
       this.dialogRef.close(data);
     }
   }
   // updateFieldFormat(typeFormat: string) {
   //    this.typeFormatService.getByLabel(typeFormat).then( typeF => {
   //       if (typeF.pageObligatoire === 'Oui') {
   //          this.mandatoryRequired = true;
   //       } else {
   //          this.mandatoryRequired = false;
   //       }
   //       if (typeF.pageLibre === 'Oui') {
   //          this.freeRequired = true;
   //       } else {
   //          this.freeRequired = false;
   //       }
   //    });
   // }
   pagesRestantes() {
       const nbPagesAdresses = this.data.pageAdresses ? 1 : 0;
       const nbCouv = this.data.sansCouv ? 0 : 1;
     return this.data.nbPages - nbCouv - nbPagesAdresses - this.data?.nbMandatory;
   }

   totalPagesOk() {
       const nbPagesAdresses = this.data.pageAdresses ? 1 : 0;
       const nbCouv = this.data.sansCouv ? 0 : 1;
       return (this.data.nbPages - nbCouv - nbPagesAdresses  >=  this.data?.nbMandatory );
   }
}
