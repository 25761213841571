import { Injectable } from '@angular/core';

import { DFApiService } from './dfapi.service';
import { AuthService } from '../user/auth.service';

@Injectable({
   providedIn: 'root',
})
export class DFApiReloaderService {
   services: DFApiService[];
   authService: AuthService;

   constructor() {
      this.services = [];
   }

   public setAuthService(authService: AuthService) {
      this.authService = authService;
   }

   public update() {
      this.services.forEach(element => {
         element.setAuthService(this.authService);
      });
   }

   public add(service: DFApiService) {
      this.services.push(service);
   }
}
